import React, { useRef } from 'react'

import Image from '/react4xp/components/Image';

// utils
import { slugify } from '/lib/reactutils/utils'

// svg
import { UxAngleDown } from '/react4xp/components/Svg/Ux/Angle'

// prop types
interface DashboardMainProps {
    cards: {
        href: string
        src: string
        title: string
    }[],
    forms: {
        href: string
        text: string
    }[],
    strings: {
        chooseCampaign: string
    }
    assets: {
        formIconUrl: string
    }
}

export default function DashboardMain({
    cards,
    forms,
    strings,
    assets
}: DashboardMainProps) {
    const dropdownRef = useRef(null)

    const handleCampaignChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex]
        const href = selectedOption.getAttribute('data-href')

        if (href) {
            window.location.href = href 
        }
    }

    return (
        <main className='page-dashboard__choose'>
            <div className='grid'>

                {cards.map((item, i) => (
                    <a href={item.href} key={i} className='block'>

                        <Image
                            src={item.src}
                            alt={item.title}
                        />

                        <p className='dash-h4'>
                            {item.title}
                        </p>

                    </a>
                ))}

                {
                    forms && (forms.length > 0) &&
                    <div className='block block--last'>

                        <Image
                            src={assets.formIconUrl}
                            alt='Skjema'
                        />

                        <div className='dropdown'>

                            <select
                                className='dash-input'
                                ref={dropdownRef}
                                onChange={handleCampaignChange}
                                defaultValue=''
                            >

                                <option value='' disabled>
                                    {strings.chooseCampaign}
                                </option>

                                {forms.map((item, i) => (
                                    <option
                                        value={slugify(item.text)}
                                        data-href={item.href}
                                        key={i}
                                    >
                                        {item.text}
                                    </option>
                                ))}

                            </select>

                            <div className='icon'>
                                <UxAngleDown />
                            </div>

                        </div>

                    </div>
                }


            </div>
        </main>
    )
}
