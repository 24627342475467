import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

// components
import Popup from '/react4xp/components/Popup'

interface FormProps {
    className?: string
    children?: React.ReactNode
    endpoint?: string
    isFormData?: boolean
    onSuccess?: (data: any) => void
    onError?: (error: any) => void
    popupSuccessContent?: any
    popupErrorContent?: any
    clearOnSuccess?: boolean
}

interface FormValues {
    [key: string]: any
}

export default function Form({
    className,
    children,
    endpoint,
    isFormData,
    onSuccess,
    onError,
    popupSuccessContent,
    popupErrorContent,
    clearOnSuccess
}: FormProps){

    // refs
    const form = useRef<HTMLFormElement>(null)
    const popupSuccess = useRef<HTMLAnchorElement>(null)
    const popupError = useRef<HTMLAnchorElement>(null)

    // useState to make the Modals invisible
    const [renderSuccessModal, setRenderSuccessModal] = useState(false)
    const [renderErrorModal, setRenderErrorModal] = useState(false)

    // close success modal
    const closeSuccessModal = () => {
        setRenderSuccessModal(false)
    }

    // close error modal
    const closeErrorModal = () => {
        setRenderErrorModal(false)
    }

    // form validations
    const methods = useForm<FormValues>({
        criteriaMode: 'all',
        mode: 'all'
    })

    // submit function
    const onSubmit: SubmitHandler<FormValues> = (data) => {

        if (form.current) {
            form.current.classList.add('is-sending')
        }

        let body
        
        if (isFormData) {
            const formData = new FormData()

            Object.keys(data).forEach(key => {
                const value = data[key]
                formData.append(key, value)
            })

            // Add files
            if (form.current) {
                Array.from(form.current.elements).forEach(element => {
                    if (element instanceof HTMLInputElement && element.type === 'file' && element.files?.length) {
                        Array.from(element.files).forEach((file, index) => {
                            formData.append(`${element.name}[${index}]`, file)
                        })
                    }
                })
            }

            /*
            Array.from(document.getElementsByTagName('form')[0].elements).forEach((element) => {
                const inputElement = element as HTMLInputElement
                if (inputElement.type === 'file' && inputElement.files) {
                    console.log(Array.from(inputElement.files))
                }
            })
            */

            body = formData
        } else {
            body = JSON.stringify(data)
        }

        // connect to the API endpoint to send the email
        fetch(endpoint, {
            method: 'post',
            body: body
        })

        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to send message.')
            }
        })

        // if success
        .then((responseData) => {
            if (onSuccess) {
                onSuccess(responseData)
            } else {
                setRenderSuccessModal(true)

                setTimeout(() => {
                    popupSuccess?.current?.click()
                    if (form.current) {
                        form.current.classList.remove('is-sending')

                        if (clearOnSuccess) {
                            form.current.reset()
                            document.dispatchEvent(new Event('resetForm'))
                        }
                    }
                }, 1000)
            }
        })

        // if error
        .catch(error => {
            console.error('Error:', error)

            if (onError) {
                onError(error)
            } else {
                setRenderErrorModal(true)
                setTimeout(() => {
                    popupError?.current?.click()
                    if (form.current) {
                        form.current.classList.remove('is-sending')
                    }
                }, 1000)
            }
            
        })
    }

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className={clsx('global-form', className)}
                ref={form}
            >
                {children}   
            </form>

            {renderSuccessModal &&
                <Popup
                    size='medium'
                    ref={popupSuccess}
                    {...popupSuccessContent}
                    onClose={popupSuccessContent.onClose || closeSuccessModal}
                />
            }

            {renderErrorModal &&
                <Popup
                    size='medium'
                    ref={popupError}
                    {...popupErrorContent}
                    onClose={closeErrorModal}
                />
            }
            
        </FormProvider>
    )
}