// libraries
import React from 'react'
import clsx from 'clsx'

// prop types
interface RichTextProps {
    content?: string
    className?: string
}

export default function RichText({ content, className }: RichTextProps) {
    return (
        <div
            className={clsx('rich-text', className)}
            dangerouslySetInnerHTML={{ __html: content }}
            suppressHydrationWarning
        />
    )
}