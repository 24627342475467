// libraries
import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'

// components
import Button from '/react4xp/components/Button'
import Breadcrumbs from '/react4xp/components/Breadcrumbs'
import Guidelines from '/react4xp/components/Guidelines'

// utils
import { getFocusableElementsOutside } from '/lib/reactutils/utils'

// svg
import { GeneralPerson } from '/react4xp/components/Svg/Icons/General'

// props
interface HeaderProps {
    bgColor?: 'white' | 'white-dark' | 'red' | string
    whiteBreadcrumb?: boolean
    transparentMenu?: boolean
    isPrototype?: boolean
    skipToMain: string
    logoSvg: string
    logoHref: string
    logoAriaLabel: string
    topMenu: Array<{ name: string, href: string, external?: boolean }>
    loginText: string
    loginHref: string
    searchHref: string
    searchText: string
    menuOpen: string
    menuClose: string
    breadcrumbs?: Array<{ name: string, href?: string }>
    fsMenuFirstCol: Array<{ name: string, link: string, external?: boolean }>
    fsMenuSecondCol: Array<{ name: string, link: string, external?: boolean }>
    fsMenuThirdCol: Array<{ name: string, link: string, external?: boolean }>
    className?: string
}

export default function Header({
    bgColor,
    whiteBreadcrumb,
    transparentMenu,
    isPrototype,
    skipToMain,
    logoSvg,
    logoHref,
    logoAriaLabel,
    topMenu,
    loginText,
    loginHref,
    searchHref,
    searchText,
    menuOpen,
    menuClose,
    breadcrumbs,
    fsMenuFirstCol,
    fsMenuSecondCol,
    fsMenuThirdCol
}: HeaderProps) {

    // custom bg color
    const customBgColor = bgColor && !['white', 'white-dark', 'red'].includes(bgColor) ? { backgroundColor: bgColor } : {}

    // animation ref
    const menuAnimationRef = useRef(null)

    // open / close fs menu
    const [isShown, setIsShown] = useState(false)

	const openCloseMenu = () => {
		setIsShown(!isShown)
	}

    // menu animation
    useGSAP(() => {
        const menuAnimation = gsap.timeline({
            paused: true
        })

        menuAnimation.to('#fs-menu', {
            opacity: 1,
            visibility: 'visible',
            ease: 'none',
            duration: 0
        })

        menuAnimation.to('#fs-menu', {
            clipPath: 'inset(0% 0% 0% 0%)',
            ease: 'power2.inOut',
            duration: 1
        })

        menuAnimation.to('.hide-on-fs', {
            autoAlpha: 0,
            x: 50,
            ease: 'power2.inOut',
            duration: .6,
            stagger: .1
        }, '-=1')

        menuAnimation.to('.open-fs-menu', {
            backgroundColor: '#fcfcf9',
            color: '#001f29',
            ease: 'power2.inOut',
            duration: .6
        }, '-=1')

        menuAnimation.to('#top-menu-logo span', {
            color: '#fcfcf9',
            ease: 'power2.inOut',
            duration: .6
        }, '-=1')
    
        menuAnimation.fromTo('#fs-menu ul li', {
            y: -50,
            autoAlpha: 0
        }, {
            y: 0,
            autoAlpha: 1,
            stagger: .05
        }, '-=.7')
        
        // store the animation timeline in the ref
        menuAnimationRef.current = menuAnimation
    })

    useEffect(() => {
        const focusable_els = document.getElementById('trap-focus')
        const focusableElementsOutside = getFocusableElementsOutside(focusable_els)

        // disable focus on all elements outside the fs-menu when it's open
        const disableFocusOutside = () => {
            focusableElementsOutside.forEach((el) => {
                el.setAttribute('tabindex', '-1')
            })
        }

        // enable focus on all elements outside the fs-menu when it's closed
        const enableFocusOutside = () => {
            focusableElementsOutside.forEach((el) => {
                el.removeAttribute('tabindex')
            })
        }

		setIsShown(isShown)

        if (menuAnimationRef.current) {
            if (isShown) {
                menuAnimationRef.current.play()
                document.body.style.overflowY = 'scroll'
                document.body.style.position = 'fixed'
                disableFocusOutside()
            } else {
                menuAnimationRef.current.reverse()
                document.body.style.overflowY = 'auto'
                document.body.style.position = 'relative'
                enableFocusOutside()
            }
        }
	}, [isShown])

    useEffect(() => {
        window.onpageshow = () => {
            menuAnimationRef.current.progress(0).pause()
            setIsShown(false)   
        }
    }, [])

    return (
        <>

            {isPrototype && <Guidelines />}

            <Button
                href='#main'
                className='skip-to-main'
                color='black'
                smallBorders
            >
                {skipToMain}
            </Button>

            <section
                id='top-menu'
                className={clsx(
                    bgColor === 'white' && 'bg-white',
                    bgColor === 'white-dark' && 'bg-white-dark',
                    bgColor === 'red' && 'bg-red'
                )}
                style={customBgColor}
            >

                <div id='trap-focus'>

                    {/* top menu */}
                    <div className='container relative z3'>

                        <div className='wrapper'>
                        
                            <a
                                href={logoHref}
                                id='top-menu-logo'
                                className='logo'
                                aria-label={logoAriaLabel}
                            >
                                <span
                                    dangerouslySetInnerHTML={{ __html: logoSvg }}
                                    suppressHydrationWarning
                                />
                            </a>

                            <div className='right'>

                                <ul>

                                    {topMenu.map((item, i) => (
                                        <li className='menu-item top-menu-item hide-on-fs' key={i}>
                                            <Button
                                                href={item.href}
                                                target={!!item.external}
                                                className={clsx( transparentMenu && 'bg-transparent' )}
                                            >
                                                {item.name}
                                            </Button>
                                        </li>
                                    ))}

                                    <li className='menu-item top-menu-item'>
                                        <Button
                                            href={loginHref}
                                            className={clsx(
                                                isShown && 'fs-menu-opened',
                                                transparentMenu && 'bg-transparent'
                                            )}
                                        >
                                            <span className='limit'>
                                                {loginText}
                                            </span>
                                            <GeneralPerson variant='outline' />
                                        </Button>
                                    </li>

                                    <li className='menu-item top-menu-item'>
                                        <Button
                                            href={searchHref}
                                            className={clsx(
                                                isShown && 'fs-menu-opened',
                                                transparentMenu && 'bg-transparent'
                                            )}
                                        >
                                            {searchText}
                                            <span className='search-icon'>
                                                <span className='circle'></span>
                                                <span className='dash'></span>
                                            </span>

                                        </Button>
                                    </li>

                                </ul>

                                <Button
                                    className={clsx(
                                        'open-fs-menu',
                                        isShown && 'active',
                                        transparentMenu && 'bg-transparent'
                                    )}
                                    onClick={openCloseMenu}
                                >

                                    <span className='text'>
                                        {isShown ? menuClose : menuOpen}
                                    </span>

                                    <span className='menu-icon'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>

                                </Button>

                            </div>

                        </div>
                    </div>

                    {/* fs menu */}
                    <div id='fs-menu'>
                        <div className='container'>
                            <div className='flex'>

                                <ul className='big-menu'>
                                    {fsMenuFirstCol.map((item, i) => (
                                        <li key={i}>
                                            <Button
                                                href={item.link}
                                                target={!!item.external}
                                                smallBorders
                                                bigButton
                                                hover='black'
                                                noUnderline
                                            >
                                                {item.name}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>

                                <ul className='menu'>
                                    {fsMenuSecondCol.map((item, i) => (
                                        <li key={i}>
                                            <Button
                                                href={item.link}
                                                target={!!item.external}
                                                smallBorders
                                                hover='black'
                                                noUnderline
                                            >
                                                {item.name}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>

                                <ul className='menu'>
                                    {fsMenuThirdCol.map((item, i) => (
                                        <li key={i}>
                                            <Button
                                                href={item.link}
                                                target={item.external}
                                                smallBorders
                                                hover='black'
                                                noUnderline
                                            >
                                                {item.name}
                                            </Button>
                                        </li>
                                    ))}

                                    <li className='login'>
                                        <Button
                                            href={loginHref}
                                            hover='black'
                                        >
                                            <span className='limit'>
                                                {loginText}
                                            </span>
                                            <GeneralPerson variant='outline' />
                                        </Button>
                                    </li>

                                    <li className='search'>
                                        <Button
                                            href={searchHref}
                                            hover='black'
                                        >

                                            {searchText}

                                            <span className='search-icon'>
                                                <span className='circle'></span>
                                                <span className='dash'></span>
                                            </span>

                                        </Button>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </div>

                </div>

                {breadcrumbs && (
                    <div className='container relative z1'>
                        <Breadcrumbs
                            className={clsx(
                                'breadcrumbs-header',
                                whiteBreadcrumb && 'white'
                            )}
                            breadcrumbs={breadcrumbs}
                        />
                    </div>
                )}

            </section>

        </>
    )
}