// libraries
import React from 'react'
import clsx from 'clsx'
import { useFormContext, RegisterOptions } from 'react-hook-form'

// svg
import { GeneralWarning } from '/react4xp/components/Svg/Icons/General'
import { UxAngleDown } from '/react4xp/components/Svg/Ux/Angle'

interface SelectProps {
    id: string
    name: string
    label?: string
    placeholder?: string
    required?: boolean
    defaultValue?: string
    disabled?: boolean
    size?: 'small' | 'medium' | 'large' | 'full-width'
    className?: string
    errorMessages?: {
        required?: string
    }
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    children?: React.ReactNode
}

export default function Select({
    id,
    name,
    label,
    required,
    defaultValue,
    disabled,
    size = 'full-width',
    className,
    errorMessages,
    onChange,
    children,
}: SelectProps) {
    const { register, formState: { errors } } = useFormContext()
    let validations: RegisterOptions = {}

    if (required) {
        validations.required = errorMessages?.required
    }

    const errorMessage = errors && errors[name]?.message

    return (
        <div className={clsx(
            'form-line',
            className,

            // input size
            size === 'small' && 'form-line--small',
            size === 'medium' && 'form-line--medium',
            size === 'large' && 'form-line--large',
            size === 'full-width' && 'form-line--full-width',
            
            errors && errors[name] && 'form-line--error'
        )}>

            <label className={clsx('form-line__label text-small')} htmlFor={id}>
                {label}
            </label>

            <div className='form-line__line-wrapper'>

                <select
                    id={id}
                    name={name}
                    className='form-line__input form-line__input--select text-small'
                    defaultValue={defaultValue}
                    disabled={disabled || false}
                    {...register(name, {
                        onChange: (e) => onChange && onChange(e),
                        ...validations
                    })}
                >
                    {children}
                </select>

                <div className='form-line__side-icon'>
                    <UxAngleDown />
                </div>

            </div>

            {errorMessage && (
                <p className={clsx('form-line__error-message', 'text-smaller')}>
                    <GeneralWarning />
                    {typeof errorMessage === 'string' && errorMessage}
                </p>
            )}

        </div>
    )
}