// libraries
import React, { useState, useRef } from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Button from '/react4xp/components/Button'
import Link from '/react4xp/components/Link'
import Form from '/react4xp/components/Form/Form'
import Input from '/react4xp/components/Form/Input'
import Checkbox from '/react4xp/components/Form/Checkbox'
import Popup from '/react4xp/components/Popup'

// svg
import { GeneralStar, GeneralChat } from '/react4xp/components/Svg/Icons/General'
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

// utils
import { slugify } from '/lib/reactutils/utils.js'

// prop types
interface EditProfileProps {
    strings: {
        profileHeader: string
        profileIntroBefore: string
        profileIntroAfter: string

        profileSuccessPopupClose: string
        profileSuccessPopupHeader: string
        profileSuccessPopupContent: string

        profileErrorPopupClose: string
        profileErrorPopupHeader: string
        profileErrorPopupContent: string

        profileFirstname: string
        profileLastname: string
        profileEmail: string
        profilePhone: string
        profileNewsletter: string

        validationRequired: string

        profileSave: string
        profileDelete: string

        profileDeletePopupClose: string
        profileDeletePopupHeader: string
        profileDeletePopupContent: string
        profileDeletePopupCancel: string
        profileDeletePopupConfirm: string

        profileLogout: string

        ideasHeader: string
        ideasIntro: string
        ideasMainButton: string
    }
    assets: {
        images: {
            base: string
            lights: string
            logosDark: string
            logosLight: string
            mask: string
        }
    }
    user: {
        firstName: string
        lastName: string
        email: string
        phone: string
        consentNewsletter: boolean
    }
    editServiceUrl: string
    deleteServiceUrl: string
    logoutUrl: string
    minKavliSubmissionPageUrl: string
    ideas: {
        title: string
        author: string
        href: string
        colorLight: boolean
        color: string
        likes: string
        comments: string
        ingredients: {
            name: string
            image: string
        }[]
    }[]
}

export default function EditProfile({
    strings,
    assets,
    user,
    editServiceUrl,
    deleteServiceUrl,
    logoutUrl,
    minKavliSubmissionPageUrl,
    ideas
}: EditProfileProps) {
    // refs
    const popupExcludeConfirmation = useRef<HTMLAnchorElement>(null)

    // useState to make the modal invisible
    const [renderExcludeConfirmation, setRenderExcludeConfirmation] = useState(false)

    // close modal
    const closeExcludeModal = () => {
        setRenderExcludeConfirmation(false)
    }

    // open modal
    const openExcludeModal = () => {
        setRenderExcludeConfirmation(true)
        setTimeout(() => {
            popupExcludeConfirmation?.current?.click()
        }, 100)
    }

    return (
        <>
            <Section bgColor='red' paddingTop='small' paddingBottom='small'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'>
                            <div className='page-user__content bg-white'>

                                <h1 className='page-user__title h2'>
                                    {strings.profileHeader}
                                </h1>

                                <p className='page-user__desc text-big'>
                                    {strings.profileIntroBefore} {(user.firstName + ' ' + user.lastName).trim()}{strings.profileIntroAfter}
                                </p>

                                <Form
                                    endpoint={editServiceUrl}
                                    className='page-user__form'
                                    onSuccess={() => {
                                        window.location.reload()
                                    }}
                                    popupSuccessContent={{ // TODO: Check if it will be necessary
                                        hasCloseButton: true,
                                        closeButtonText: strings.profileSuccessPopupClose,
                                        title: strings.profileSuccessPopupHeader,
                                        content: strings.profileSuccessPopupContent,
                                        actionButtons: {
                                            primary: {
                                                text: strings.profileSuccessPopupClose
                                            }
                                        }
                                    }}
                                    popupErrorContent={{
                                        hasCloseButton: true,
                                        closeButtonText: strings.profileErrorPopupClose,
                                        title: strings.profileErrorPopupHeader,
                                        content: strings.profileErrorPopupContent,
                                        actionButtons: {
                                            primary: {
                                                text: strings.profileErrorPopupClose
                                            }
                                        }
                                    }}
                                >
                                    <Input
                                        name='Fornavn'
                                        id='edit-profile-first-name'
                                        label={strings.profileFirstname}
                                        type='text'
                                        disabled
                                        value={user.firstName}
                                        size='large'
                                    />

                                    <Input
                                        name='lastname'
                                        id='edit-profile-last-name'
                                        label={strings.profileLastname}
                                        type='text'
                                        disabled
                                        value={user.lastName}
                                        className='page-user__margin-bottom'
                                        size='large'
                                    />

                                    <Input
                                        name='email'
                                        id='edit-profile-email'
                                        label={strings.profileEmail}
                                        type='email'
                                        value={user.email}
                                        disabled
                                    />

                                    <Input
                                        name='phone'
                                        id='edit-profile-phone'
                                        label={strings.profilePhone}
                                        type='tel'
                                        value={user.phone}
                                        className='page-user__margin-bottom'
                                        size='medium'
                                    />

                                    <Checkbox
                                        id='edit-profile-newsletter'
                                        name='newsletter'
                                        checked={user.consentNewsletter}
                                        className='page-user__margin-bottom'
                                    >
                                        {strings.profileNewsletter}
                                    </Checkbox>

                                    <div className='page-user__two-buttons'>

                                        <Button
                                            color='black'
                                            hover='red'
                                            type='submit'
                                            noUnderline
                                        >
                                            {strings.profileSave}
                                        </Button>

                                        <Link
                                            type='button'
                                            text={strings.profileDelete}
                                            onClick={openExcludeModal}
                                        />

                                        {renderExcludeConfirmation &&
                                            <Popup
                                                size='medium'
                                                ref={popupExcludeConfirmation}
                                                hasCloseButton
                                                closeButtonText={strings.profileDeletePopupClose}
                                                title={strings.profileDeletePopupHeader}
                                                content={strings.profileDeletePopupContent}
                                                className='popup--exclude-popup'
                                                actionButtons={{
                                                    primary: {
                                                        text: strings.profileDeletePopupCancel,
                                                        onClick: () => {
                                                            closeExcludeModal()
                                                        }
                                                    },
                                                    secondary: {
                                                        text: strings.profileDeletePopupConfirm,
                                                        onClick: () => {
                                                            window.location.href = deleteServiceUrl
                                                        }
                                                    }
                                                }}
                                                onClose={closeExcludeModal}
                                            />
                                        }

                                    </div>

                                    <Button
                                        href={logoutUrl}
                                        color='transparent'
                                        hover='blue'
                                        hasBorders
                                        noUnderline
                                    >
                                        {strings.profileLogout}
                                    </Button>

                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            {(ideas.length > 0) ? (
                <>
                    <Section bgColor='red' className='page-user__ideas'>
                        <div className='container'>

                            <h2 className='h2 white title-top'>
                                {strings.ideasHeader}
                            </h2>

                            <div className='min-kavli-grid'>
                                <div className='min-kavli-grid__wrapper'>

                                    {ideas.map((item, i) => (
                                        <div key={i} className='grid-item bg-white'>
                                            <a href={item.href}>

                                                <div className='image'>

                                                    <img src={assets.images.base} alt='Min Kavlifavoritt' className='base' />
                                                    <img src={assets.images.lights} alt='Min Kavlifavoritt' className='lights' />

                                                    <img
                                                        src={clsx(
                                                            item.colorLight === false ? assets.images.logosDark : assets.images.logosLight
                                                        )}
                                                        alt='Min Kavlifavoritt'
                                                        className='logos'
                                                    />

                                                    <div className='texts'>
                                                        <div className='texts-wrapper'>
                                                            <span>{item.ingredients[0].name}</span> & <span>{item.ingredients[1].name}</span> & <span>{item.ingredients[2].name}</span>
                                                        </div>
                                                    </div>

                                                    <div className='mask' style={{
                                                        maskImage: `url('${assets.images.mask}')`,
                                                        WebkitMaskImage: `url('${assets.images.mask}')`
                                                    }}>

                                                        <div className='color' style={{ backgroundColor: item.color }}></div>

                                                        <div className='ingredient-01'>

                                                            <img
                                                                src={item.ingredients[0].image}
                                                                alt={item.ingredients[0].name}
                                                                className='img'
                                                                data-ingredient={slugify(item.ingredients[0].name)}
                                                            />

                                                        </div>

                                                        <div className='ingredient-02'>

                                                            <img
                                                                src={item.ingredients[1].image}
                                                                alt={item.ingredients[1].name}
                                                                className='img'
                                                                data-ingredient={slugify(item.ingredients[1].name)}
                                                            />

                                                        </div>

                                                        <div className='ingredient-03'>

                                                            <img
                                                                src={item.ingredients[2].image}
                                                                alt={item.ingredients[2].name}
                                                                className='img'
                                                                data-ingredient={slugify(item.ingredients[2].name)}
                                                            />

                                                        </div>

                                                    </div>

                                                </div>

                                                <p className='text-smaller author'>
                                                    {item.author}
                                                </p>

                                                <h3 className='h4 title'>
                                                    {item.title}
                                                </h3>

                                                <div className='icons text-smaller'>

                                                    <span className='icons__item'>
                                                        <GeneralStar variant='outline' />
                                                        <span>
                                                            {item.likes ? item.likes : '0'}
                                                        </span>
                                                    </span>

                                                    <span className='icons__item'>
                                                        <GeneralChat variant='outline' />
                                                        <span>
                                                            {item.comments ? item.comments : '0'}
                                                        </span>
                                                    </span>

                                                </div>

                                                <div className='arrow'>
                                                    <UxArrowRight />
                                                </div>

                                            </a>
                                        </div>
                                    ))}

                                </div>
                            </div>

                        </div>
                    </Section>
                </>
            ) : (
                <Section bgColor='red' paddingTop='none'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'>
                                <div className='page-user__content bg-white'>

                                    <h2 className='page-user__title h3'>
                                        {strings.ideasHeader}
                                    </h2>

                                    <p className='page-user__desc negative-margin'>
                                        {strings.ideasIntro}
                                    </p>

                                    <Button
                                        href={minKavliSubmissionPageUrl}
                                        color='transparent'
                                        hover='blue'
                                        hasBorders
                                        noUnderline
                                    >
                                        {strings.ideasMainButton}
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
            )}
        </>
    )
}
