// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Image from '/react4xp/components/Image'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// svg
import { GeneralChat, GeneralStar } from '/react4xp/components/Svg/Icons/General'

// prop types
interface ProductCardProps {
    bgColor?: 'white' | 'white-dark'
    href?: string
    external?: boolean
    src?: string
    srcMedium?: string
    alt?: string
    width?: number
    sizes?: string
    title?: string
    comments?: string
    rating?: string
    noCommentsRating?: boolean
    className?: string
}

export default function ProductCard({
    bgColor,
    href,
    external,
    src,
    srcMedium,
    alt,
    width,
    sizes,
    title,
    comments,
    rating,
    className,
    noCommentsRating,
    ...rest
}: ProductCardProps) {
    return (
        <a
            href={href}
            target={external ? '_blank' : undefined}
            className={clsx(
                'product-card',
                className
            )}
            {...rest}
        >
            <div
                className={clsx(
                    'product-card__mask',

                    // bg colors
                    bgColor === 'white' && 'bg-white',
                    bgColor === 'white-dark' && 'bg-white-dark',
                )}
            >

                {src && (
                    <div className='product-card__image'>
                        <Image
                            src={src}
                            srcMedium={srcMedium}
                            alt={alt}
                            width={width}
                            sizes={sizes}
                        />
                    </div>
                )}

                <div className={clsx(
                    'product-card__wrapper',
                    noCommentsRating && 'product-card__wrapper--no-comments-rating'
                )}>

                    {isNonWhitespaceString(title) && (
                        <h3 className='product-card__wrapper__title h4'>
                            {title}
                        </h3>  
                    )}

                    {noCommentsRating ?? (
                        <div className='product-card__wrapper__icons text-smaller'>

                            <span className='product-card__wrapper__icons__item'>
                                <GeneralChat variant='outline' />
                                <span>
                                    {comments ? comments : '0'}
                                </span>
                            </span>
                            
                            <span className='product-card__wrapper__icons__item'>
                                <GeneralStar variant='outline' />
                                <span>
                                    {rating ? rating : '--'}
                                </span>
                            </span>

                        </div>
                    )}
                    
                </div>
            </div>

        </a>
    )
}