// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Link from '/react4xp/components/Link'
import AnimatedArrow from '/react4xp/components/AnimatedArrow'
import ArticleCard from '/react4xp/components/ArticleCard'
import RecipeCard from '/react4xp/components/RecipeCard'
import ProductCard from '/react4xp/components/ProductCard'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

// prop types
interface ListContentArticlesProps {
    
    // section props
    section: {
        bgColor: 'red' | 'white' | 'white-dark' | string
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
        title?: string
    }

    content: 'articles' | 'recipes' | 'products'
    boxColor?: 'white' | 'white-dark'
    howManyCols?: 2 | 3
    isWhite?: boolean

    // articles props
    articles?: Array<{
        href?: string
        external?: boolean
        src?: string
        srcMedium?: string
        alt?: string
        date?: string
        title?: string
        desc?: string
        redOutline?: boolean
    }>

    // recipes props
    recipes?: Array<{
        href?: string
        external?: boolean
        src?: string
        srcMedium?: string
        alt?: string
        title?: string
        time?: string
        rating?: string
        desc?: string
        redOutline?: boolean
    }>

    // products props
    products?: Array<{
        href?: string
        external?: boolean
        src?: string
        srcMedium?: string
        alt?: string
        title?: string
        comments?: string
        rating?: string
    }>

    bottomLink?: {
        href: string
        external?: boolean
        text?: string
    }

    className?: string
}

export default function ListContent({
    section,
    howManyCols,
    boxColor,
    isWhite,
    content,
    articles,
    recipes,
    products,
    bottomLink,
    className
}: ListContentArticlesProps) {
    return (
        <Section
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            className={clsx('list-content', className)}
        >
            <div className='container'>

                {isNonWhitespaceString(section.title) && (
                    <div className={clsx('list-content__title', isWhite && 'white')}>
                        <h2 className='h2'>
                            {section.title}
                        </h2>
                    </div>
                )}

                {content === 'articles' &&
                    articles && articles.length > 0 && (
                        <div className={clsx(
                            'row',
                            howManyCols === 2 && 'offset-xl-1',
                        )}>
                            {articles.map((item, i) => (
                                <div
                                    className={clsx(
                                        howManyCols === 2 && 'col-md-6 col-xl-5',
                                        howManyCols === 3 && 'col-md-6 col-lg-4'
                                    )}
                                    key={i}
                                >
                                    <ArticleCard
                                        href={item.href}
                                        external={item.external}
                                        src={item.src}
                                        srcMedium={item.srcMedium}
                                        alt={item.alt}
                                        width={400}
                                        sizes='( max-width: 992px ) 100vw, 400px'
                                        title={item.title}
                                        desc={item.desc}
                                        redOutline={item.redOutline}
                                        whiteText={isWhite}
                                    />
                                </div>
                            ))}
                        </div>  
                    )
                }

                {content === 'recipes' &&
                    recipes && recipes.length > 0 && (
                        <div className={clsx(
                            'row',
                            recipes.length === 2 && 'offset-xl-1',
                        )}>
                            {recipes.map((item, i) => (
                                <div
                                    className={clsx(
                                        recipes.length === 2 ? 'col-md-6 col-xl-5' : 'col-md-6 col-lg-4'
                                    )}
                                    key={i}
                                >
                                    <RecipeCard
                                        bgColor={boxColor}
                                        href={item.href}
                                        external={item.external}
                                        src={item.src}
                                        srcMedium={item.srcMedium}
                                        alt={item.alt}
                                        width={400}
                                        sizes='( max-width: 992px ) 100vw, 400px'
                                        title={item.title}
                                        time={item.time}
                                        rating={item.rating}
                                        desc={item.desc}
                                        redOutline={item.redOutline}
                                        whiteText={isWhite}
                                    />
                                </div>
                            ))}
                        </div>  
                    )
                }

                {content === 'products' &&
                    products && products.length > 0 && (
                        <div className='row'>
                            {products.map((item, i) => (
                                <div className='col-md-10 offset-md-1 col-lg-4 offset-lg-0' key={i}>
                                    <ProductCard
                                        bgColor={boxColor}
                                        href={item.href}
                                        external={item.external}
                                        src={item.src}
                                        srcMedium={item.srcMedium}
                                        alt={item.alt}
                                        width={400}
                                        sizes='( max-width: 992px ) 100vw, 400px'
                                        title={item.title}
                                        comments={item.comments}
                                        rating={item.rating}
                                    />
                                </div>
                            ))}
                        </div>  
                    )
                }

                {bottomLink && (
                    <div className='list-content__bottom-link'>
                        {isNonWhitespaceString(bottomLink.text) ? (
                            <Link
                                text={bottomLink.text}
                                href={bottomLink.href}
                                target={!!bottomLink.external}
                                iconPosition='right'
                                icon={<UxArrowRight />}
                                isWhite={isWhite}
                                noMotionMobile
                            />
                        ) : (
                            isNonWhitespaceString(bottomLink.href) && (
                                <a
                                    href={bottomLink.href}
                                    target={bottomLink.external ? '_blank' : undefined}
                                    className={clsx(
                                        'list-content__bottom-link__arrow',
                                        isWhite && 'white'
                                    )}
                                >
                                    <AnimatedArrow isWhite={isWhite} />
                                </a>
                            )
                        )}
                    </div>
                )}

            </div>
        </Section>
        
    )
}