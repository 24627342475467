// libraries
import React from 'react'

// components
import Section from '/react4xp/components/Section'
import Button from '/react4xp/components/Button'
import Link from '/react4xp/components/Link'

// prop types
interface LoginProps {
    strings: {
        loginHeader: string
        loginButton: string
        registerButton?: string
    }
    profilePageUrl: string
    registerUrl: string
}

export default function Login({
    strings,
    profilePageUrl,
    registerUrl
}: LoginProps) {
    return (
        <Section bgColor='red' paddingTop='small'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'>
                        <div className='page-user__content bg-white'>

                            <h1 className='page-user__title h2'>
                                {strings.loginHeader}
                            </h1>

                            <Button
                                color='black'
                                hover='red'
                                noUnderline
                                href={profilePageUrl}
                            >
                                {strings.loginButton}
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}
