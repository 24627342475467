//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'
import RichText from '/react4xp/components/RichText'
import LinkList, { LinkListProps } from '/react4xp/components/LinkList'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface CampaignFeaturedProps {
    image?: {
        position: 'top' | 'bottom'
        src: string
        srcMedium: string
        srcSmall: string
        alt?: string
    }
    title?: string
    content?: string
    listLinks?: LinkListProps[]
}

export default function CampaignFeatured({
    image,
    title,
    content,
    listLinks
}: CampaignFeaturedProps) {
    return (
        <Section
            bgColor='white'
            paddingTop={image.position === 'top' ? 'none' : null}
            paddingBottom={image.position === 'bottom' ? 'none' : null}
            className='campaign-featured'
        >

            {isNonWhitespaceString(image?.src) && image.position === 'top' && (
                <div className='campaign-featured__image campaign-featured__image--top'>
                    <Image
                        src={image?.src} // 1600x1100
                        srcMedium={image?.srcMedium} // 1000x700
                        srcSmall={image?.srcSmall} // 600x500
                        alt={image?.alt}
                        className='cover'
                    />
                </div>
            )}

            {isNonWhitespaceString(title || content) && (
                <div className='campaign-featured__content'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                                <div className={clsx(
                                    'campaign-featured__red-box white',
                                    image.src && image.position === 'top' && 'campaign-featured__red-box--has-image-top',
                                    image.src && image.position === 'bottom' && 'campaign-featured__red-box--has-image-bottom'
                                )}>
                                    <div className='inner-wrapper'>

                                        {isNonWhitespaceString(title) && (
                                            <h2 className='h1 title'>
                                                {title}
                                            </h2>
                                        )}

                                        {isNonWhitespaceString(content) && (
                                            <RichText content={content} />
                                        )}

                                        {listLinks && (
                                            <div className='list'>
                                                {listLinks.map((item: LinkListProps, i) => (
                                                    <LinkList
                                                        key={i}
                                                        style='inline'
                                                        {...item}
                                                    />
                                                ))}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isNonWhitespaceString(image?.src) && image.position === 'bottom' && (
                <div className='campaign-featured__image campaign-featured__image--bottom'>
                    <Image
                        src={image?.src} // 1600x1100
                        srcMedium={image?.srcMedium} // 1000x700
                        srcSmall={image?.srcSmall} // 600x500
                        alt={image?.alt}
                        className='cover'
                    />
                </div>
            )}

        </Section>
    )
}