// libraries
import React from 'react'
import clsx from 'clsx'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

// prop types
interface MacroLinkListProps {
    links: Array<{
        name: string
        href: string
        external?: boolean
    }>

    className?: string
}

export default function MacroLinkList({
    links,
    className
}: MacroLinkListProps) {
    return (
        <ul className={clsx('macros--link-list', className)}>
            {links.map((item, i) => (
                isNonWhitespaceString(item.name) && (
                    <li key={i}>
                        <a href={item.href} target={item.external ? '_blank' : undefined}>
                            <UxArrowRight />
                            <span>{item.name}</span>
                        </a>
                    </li>
                )
            ))}
        </ul>
    )
}