// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Link from '/react4xp/components/Link'
import Image from '/react4xp/components/Image'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'
import { Nyhet, OneHundredAr, Fint, Goot, Mykt, Nam, BliMed, Goy, Heart, Lett, Protein } from '/react4xp/components/Svg/Others/NewProductsIcon'

// prop types
interface NewProductsProps {
    
    // section props
    section: {
        bgColor: 'white' | 'white-dark'
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
        title?: string
    }

    // products props
    products?: Array<{
        href: string
        external?: boolean
        icon?: {
            src: 'nyhet' | '100ar' | 'fint' | 'goot' | 'mykt' | 'nam' | 'bli med' | 'goy' | 'heart' | 'lett' | 'protein' | string
            position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
        }
        product: string
        alt?: string
        tilt?: 'left' | 'right'
        title?: string
    }>

    bottomLink?: {
        href: string
        external?: boolean
        text?: string
    }

    className?: string
}

export default function NewProducts({
    section,
    products,
    bottomLink,
    className
}: NewProductsProps) {
    return (
        <Section
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            className={clsx('new-products', className)}
        >
            <div className='container'>

                {isNonWhitespaceString(section.title) && (
                    <h2 className='h2 new-products__title'>
                        {section.title}
                    </h2>
                )}

                <div className='row'>
                    {products.map((item, i) => (
                        <div className='col-md-6 col-lg-4' key={i}>
                            <a
                                href={item.href}
                                target={item.external ? '_blank' : undefined}
                                className={clsx(
                                    'new-products__block',
                                    item.tilt === 'left' && 'tilt-left',
                                    item.tilt === 'right' && 'tilt-right'
                                )}
                            >

                                <div className='image'>

                                    {item.icon && (
                                        <div className={clsx(
                                            'icon',
                                            item.icon.position === 'top-left' && 'top-left',
                                            item.icon.position === 'top-right' && 'top-right',
                                            item.icon.position === 'bottom-left' && 'bottom-left',
                                            item.icon.position === 'bottom-right' && 'bottom-right'
                                        )}>
                                            {item.icon.src === 'nyhet' && <Nyhet />}
                                            {item.icon.src === '100ar' && <OneHundredAr />}
                                            {item.icon.src === 'fint' && <Fint />}
                                            {item.icon.src === 'goot' && <Goot />}
                                            {item.icon.src === 'mykt' && <Mykt />}
                                            {item.icon.src === 'nam' && <Nam />}
                                            {item.icon.src === 'bli med' && <BliMed />}
                                            {item.icon.src === 'goy' && <Goy />}
                                            {item.icon.src === 'heart' && <Heart />}
                                            {item.icon.src === 'lett' && <Lett />}
                                            {item.icon.src === 'protein' && <Protein />}

                                            {!['nyhet', '100ar', 'fint', 'goot', 'mykt', 'nam', 'bli med', 'goy', 'heart', 'lett', 'protein'].includes(item.icon.src) && (
                                                <Image
                                                    src={item.icon.src}
                                                    alt={clsx(
                                                        item.icon.src === 'nyhet' && 'Nyhet',
                                                        item.icon.src === '100ar' && '100Ar',
                                                        item.icon.src === 'fint' && 'Fint',
                                                        item.icon.src === 'goot' && 'Goot',
                                                        item.icon.src === 'mykt' && 'Mykt',
                                                        item.icon.src === 'nam' && 'Nam',
                                                        item.icon.src === 'bli med' && 'BliMed',
                                                        item.icon.src === 'goy' && 'Goy',
                                                        item.icon.src === 'heart' && 'Heart',
                                                        item.icon.src === 'lett' && 'Lett',
                                                        item.icon.src === 'protein' && 'Protein'
                                                    )}
                                                />
                                            )}

                                        </div>
                                    )}

                                    <Image
                                        src={item.product}
                                        alt={item.alt}
                                        className='product-img'
                                    />

                                </div>

                                {isNonWhitespaceString(item.title) && (
                                    <h3 className='h4'>
                                        {item.title}
                                    </h3>  
                                )}

                                <UxArrowRight />

                            </a>
                        </div>
                    ))}
                </div>

                {isNonWhitespaceString(bottomLink.text) && (    
                    <Link
                        text={bottomLink.text}
                        href={bottomLink.href}
                        target={!!bottomLink.external}
                        iconPosition='right'
                        icon={<UxArrowRight />}
                        noMotionMobile
                        className='new-products__bottom-link'
                    />
                )}

            </div>
        </Section>
        
    )
}