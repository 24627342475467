// libraries
import React from 'react'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'
import Button from '/react4xp/components/Button'
import RichText from '/react4xp/components//RichText'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
interface BannerMinKavliProps {
    section: {
        bgColor: 'white' | 'white-dark'
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
    }
    image?: string
    alt?: string
    title?: string
    desc?: string
    cta?: {
        text: string
        href: string
        external?: boolean
    }
}

export default function BannerMinKavli({
    section,
    image,
    alt,
    title,
    desc,
    cta
}: BannerMinKavliProps) {
    return (
        <Section
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            className='banner-min-kavli'
        >
            <div className='container'>
                <div className='banner-min-kavli__box'>
                    <div className='flex offset-lg-1'>
                        <div className='row'>

                            <div className='col-md-6 col-lg-5 box__left'>
                                {
                                    image &&
                                    <Image
                                        src={image}
                                        alt={alt}
                                        width={300}
                                    />
                                }
                            </div>

                            <div className='col-md-6 col-lg-5 box__right'>

                                {isNonWhitespaceString(title) && (
                                    <h2 className='h2 white'>
                                        {title}
                                    </h2>
                                )}

                                {isNonWhitespaceString(desc) && (
                                    <RichText content={desc} className='white' />
                                )}

                                {isNonWhitespaceString(cta?.text) && (
                                    <Button
                                        href={cta?.href}
                                        hover='blue'
                                        target={cta?.external}
                                        noUnderline
                                    >
                                        {cta?.text}
                                    </Button>
                                )}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}