// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'
import { GeneralExternal } from '/react4xp/components/Svg/Icons/General'

// prop types
interface ContentCardProps {

    // section props
    section: {
        bgColor: 'white' | 'white-dark' | 'yellow-light' | 'red-light'
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
    }

    // content card props
    image: {
        position: 'left' | 'right'
        tilt?: 'left' | 'right'
        redOutline?: boolean
        hasHeartIcon?: boolean
        src: string
        alt?: string
    }
    
    title?: string
    text?: string
    href?: string
    external?: boolean
}

export default function ContentCard({
    section,
    image,
    title,
    text,
    href,
    external
}: ContentCardProps) {

    const Element = href ? 'a' : 'div'

    const elementProps = {
        href: href || null,
        target: external ? '_blank' : undefined,
    }

    return (
        <Section
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            className='content-card'
        >
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 offset-lg-1'>
                        <div className={clsx(
                            'row inner-row',
                            image?.position === 'right' && 'row-reverse'
                        )}>

                            <Element {...elementProps} className='col-md-6 content-card__image'>

                                {image.hasHeartIcon && (
                                    <div className={clsx(
                                        'heart-icon',
                                        image.tilt === 'left' && 'heart-icon--left',
                                        image.tilt === 'right' && 'heart-icon--right',
                                    )}>
                                        <svg width='162' height='158' viewBox='0 0 162 158' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M145.431 84.4423C147.962 80.874 150.507 77.2841 152.944 73.5837L152.949 73.5826C178.52 34.7623 144.697 -18.8954 102.162 7.52448C93.5039 12.8964 87.9955 18.3523 70.3137 45.2778C65.7448 52.2334 65.4331 52.0668 56.4116 47.2457C55.7965 46.9169 55.1409 46.5666 54.4407 46.1953C35.6779 36.2521 20.3949 39.1044 8.09036 53.6526C-2.59515 66.2756 -5.29129 89.9806 19.5115 109.2C41.6558 126.356 76.6874 142.015 106.128 153.794C119.667 159.209 125.398 160.24 123.867 145.408C121.115 118.735 133.09 101.847 145.431 84.4423ZM143.262 77.0658C152.408 57.0271 154.185 42.8776 144.798 24.3471C130.859 -0.988854 93.9468 10.1648 70.751 51.5235C70.6641 51.6783 70.5794 51.8299 70.4965 51.9781C68.047 56.3594 67.2064 57.8631 60.5658 54.5685C41.8141 45.2717 27.4377 47.9705 17.5351 60.3632C7.42835 72.664 9.25434 92.8303 29.1712 106.904C49.7264 121.612 73.9371 135.874 101.283 147.183C102.234 147.569 103.204 147.985 104.177 148.402C112.625 152.025 121.234 155.716 118.932 139.977C116.014 120.035 130.885 96.8233 138.86 84.3759C140.948 81.1177 142.562 78.5971 143.262 77.0658Z' fill='#DA291C'/></svg>
                                    </div>
                                )}

                                {image.src && (
                                    <div className={clsx(
                                        'image-box',
                                        image.redOutline && 'image-box--red-outline',
                                        image.tilt === 'left' && 'image-box--tilt-left',
                                        image.tilt === 'right' && 'image-box--tilt-right'
                                    )}>
                                        <Image
                                            src={image.src}
                                            alt={image.alt}
                                            width={550}
                                            className='cover'
                                        />
                                    </div>
                                )}

                            </Element>

                            <div className='col-md-6 content-card__content'>

                                {isNonWhitespaceString(title) && (
                                    <Element {...elementProps}>
                                        <h2 className='h3'>
                                            {title}
                                        </h2>
                                    </Element>
                                )}

                                {isNonWhitespaceString(text) && (
                                    <div
                                        className='content'
                                        dangerouslySetInnerHTML={{ __html: text }}
                                        suppressHydrationWarning
                                    />
                                )}

                                {href && (
                                    <Element {...elementProps} className='arrow'>
                                        {!!elementProps.target === external ? (
                                            <GeneralExternal />
                                        ) : (
                                            <UxArrowRight />
                                        )}
                                    </Element>
                                )}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}