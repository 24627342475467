//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Button from '/react4xp/components/Button'
import RichText from '/react4xp/components/RichText'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface InfoBoxProps {
    section: {
        bgColor: 'red' | 'red-light'
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
    }
    title?: string
    text?: string
    cta?: {
        text: string
        href: string
        external?: boolean
    }
}

export default function InfoBox({
    section,
    title,
    text,
    cta
}: InfoBoxProps) {
    return (
        <Section
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            className='infos-box'
        >
            <div className='container'>
                <div className='row'>
                    <div className={clsx(
                        section.bgColor === 'red' ? 'col-md-10 offset-md-1' : 'col-md-10 offset-md-1 col-xl-8 offset-xl-2'
                    )}>
                        <div className={clsx(
                            'info-box__box',
                            section.bgColor === 'red' && 'spaced'
                        )}>

                            {isNonWhitespaceString(title) && (
                                <h2 className={clsx(section.bgColor === 'red' ? 'h2' : 'h3')}>
                                    {title}
                                </h2>
                            )}

                            {isNonWhitespaceString(text) && (
                                <RichText
                                    className='text-big'
                                    content={text}
                                />
                            )}

                            {isNonWhitespaceString(cta?.text) && (
                                <Button
                                    hasBorders={section.bgColor === 'red' ? false : true}
                                    href={cta.href}
                                    color={section.bgColor === 'red' ? 'red' : null}
                                    hover='black'
                                    noUnderline
                                    target={cta.external}
                                >
                                    {cta.text}
                                </Button>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}