//libraries
import React from 'react'

// components
import ContactPhone from '/react4xp/components/ContactPhone'
import Section from '/react4xp/components/Section'

// prop types
export interface ContactPhoneSectionProps {
    title: string
    phones: {
        text: string,
        phone: string
    }[]
}

export default function ContactPhoneSection({
    title,
    phones
}: ContactPhoneSectionProps) {
    return (
        <Section bgColor='red'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
                        <ContactPhone
                            title={title}
                            phones={phones}
                        />
                    </div>
                </div>
            </div>
        </Section>
    )
}