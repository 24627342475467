// libraries
import React from 'react'

// components
import Link from '/react4xp/components/Link'

// svg
import { GeneralPhoneAlt } from '/react4xp/components/Svg/Icons/General'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
interface ContactPhoneProps {
    title?: string
    phones?: Array<{
        text: string
        phone: string
    }>
}

export default function ContactPhone({
    title,
    phones
}: ContactPhoneProps) {
    return (
        <div className='contact-phone'>

            <div className='contact-phone__top'>

                <GeneralPhoneAlt />

                {isNonWhitespaceString(title) && (
                    <h2 className='h3'>
                        {title}
                    </h2>
                )}

            </div>

            <div className='contact-phone__phones'>

                {phones?.map((item, i) => (
                    <div className='phone-line' key={i}>

                        <p>
                            <b>
                                {item.text}:
                            </b>
                        </p>

                        <Link
                            text={item.phone}
                            href={`tel:${item.phone}`}
                        />

                    </div>
                ))}

            </div>

		</div>
    )
}