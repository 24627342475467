// libraries
import React, { useState } from 'react'
import clsx from 'clsx'
import { useFormContext, RegisterOptions } from 'react-hook-form'

// svg
import { GeneralWarning, GeneralEye, GeneralEyeSlash } from '/react4xp/components/Svg/Icons/General'

interface InputProps {
    id: string
    name: string
    label?: string
    type: string
    placeholder?: string
    required?: boolean
    maxLength?: number
    minLength?: number
    value?: string
    disabled?: boolean
    size?: 'small' | 'medium' | 'large' | 'full-width'
    isWhite?: boolean
    showHidePassword?: boolean
    className?: string
    errorMessages?: {
        required?: string
        maxLength?: string
        minLength?: string
        invalidEmail?: string
    }
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export default function Input({
    id,
    name,
    label,
    type,
    placeholder,
    required,
    maxLength,
    minLength,
    value,
    disabled,
    size = 'full-width',
    isWhite,
    showHidePassword,
    className,
    errorMessages,
    onChange = () => {},
    onKeyDown
}: InputProps) {

    const formContext = useFormContext()
    const register = formContext ? formContext.register : () => ({})
    const errors = formContext ? formContext.formState.errors : {}

    let validations: RegisterOptions = {
        onChange: (e) => onChange(e),
        required
    }

    if (required) {
        validations.required = errorMessages?.required
    }

    if (maxLength) {
        validations.maxLength = {
            value: maxLength,
            message: errorMessages?.maxLength
        }
    }

    if (minLength) {
        validations.minLength = {
            value: minLength,
            message: errorMessages?.minLength
        }
    }

    // add pattern validation for email type
    if (type === 'email') {
        validations.pattern = {
            value: /\S+@\S+\.\S+/,
            message: errorMessages?.invalidEmail
        }
    }

    const errorMessage = errors && errors[name]?.message

    // show / hide password function
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible)
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && onKeyDown) {
            onKeyDown(event)
        }
    }

    return (
        <div className={clsx(
            'form-line',
            className,

            // input size
            size === 'small' && 'form-line--small',
            size === 'medium' && 'form-line--medium',
            size === 'large' && 'form-line--large',
            size === 'full-width' && 'form-line--full-width',
            errors && errors[name] && 'form-line--error',
            isWhite && 'white'
        )}>

            <label className={clsx('form-line__label text-small')} htmlFor={id}>
                {label}
            </label>

            <div className='form-line__line-wrapper'>

                <input
                    type={isPasswordVisible ? 'text' : type}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    className='form-line__input text-small'
                    defaultValue={value}
                    pattern={type === 'tel' ? '[0-9]*' : undefined}
                    disabled={disabled || false}
                    onKeyDown={handleKeyPress}
                    onChange={onChange}
                    {...(register ? register(name || '', validations) : {})}
                />

                {showHidePassword && (
                    <button
                        type='button'
                        className={clsx(
                            'form-line__side-button show-hide-password',
                            isPasswordVisible && 'is-visible'
                        )}
                        onClick={togglePasswordVisibility}
                    >
                        {isPasswordVisible ? <GeneralEyeSlash /> : <GeneralEye />}
                    </button>
                )}

            </div>

            {errorMessage && (
                <p className={clsx('form-line__error-message', 'text-smaller')}>
                    <GeneralWarning />
                    {typeof errorMessage === 'string' && errorMessage}
                </p>
            )}

        </div>
    )
}