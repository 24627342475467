//libraries
import React from 'react'

// components
import LinkList from '/react4xp/components/LinkList'
import Section from '/react4xp/components/Section'

// prop types
export interface LinkListSectionProps {
    links: {
        text: string,
        link: string,
        isExternal: boolean,
    }[]
}

export default function LinkListSection({
    links
}: LinkListSectionProps) {
    return (
        <Section bgColor='red'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
                        {links.map((item, i) => (
                            <LinkList
                                key={i}
                                text={item.text}
                                link={item.link}
                                isExternal={item.isExternal}
                                style='block'
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Section>
    )
}