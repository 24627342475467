// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Image from '/react4xp/components/Image'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
interface ArticleCardProps {
    href?: string
    external?: boolean
    src?: string
    srcMedium?: string
    alt?: string
    width?: number
    sizes?: string
    title?: string
    desc?: string
    whiteText?: boolean
    redOutline?: boolean
    className?: string
}

export default function ArticleCard({
    href,
    external,
    src,
    srcMedium,
    alt,
    width,
    sizes,
    title,
    desc,
    whiteText,
    redOutline,
    className
}: ArticleCardProps) {
    return (
        <a
            href={href}
            target={external && '_blank'}
            className={clsx(
                'article-card',
                className
            )}
        >
            <div className='article-card__mask'>

                {src && (
                    <>

                        <div className='article-card__image'>
                            <Image
                                src={src}
                                srcMedium={srcMedium}
                                alt={alt}
                                width={width}
                                sizes={sizes}
                                className='cover'
                            />
                        </div>

                        {redOutline && (
                            <div className='article-card__red-outline' />
                        )}

                    </>
                )}

                <div className={clsx('article-card__wrapper', whiteText && 'white')}>

                    {isNonWhitespaceString(title) && (
                        <h3 className='article-card__wrapper__title h4'>
                            {title}
                        </h3>
                    )}

                    {isNonWhitespaceString(desc) && (
                        <div
                            className='article-card__wrapper__desc text-small'
                            dangerouslySetInnerHTML={{ __html: desc }}
                            suppressHydrationWarning
                        />
                    )}

                    <div className='article-card__wrapper__arrow'>
                        <UxArrowRight />
                    </div>

                </div>

            </div>
        </a>
    )
}