//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'
import Button from '/react4xp/components/Button'
import Video from '/react4xp/components/Video'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'
import RichText from '../RichText'

// prop types
export interface LandingPageHeroProps {
    bgColor: 'red' | 'white'
    title?: string
    image?: {
        src: string
        srcMedium: string
        srcSmall: string
        alt?: string
    }
    video?: {
        videoType: 'youtube' | 'vimeo' | 'default'
        playText: string
        poster?: string
        posterMedium?: string
        url: string
    }
    content?: string
    button?: {
        text?: string
        href: string
        external?: boolean
    }
}

export default function LandingPageHero({
    bgColor,
    title,
    image,
    video,
    content
}: LandingPageHeroProps) {
    return (
        <Section
            bgColor={bgColor}
            paddingTop='smaller'
            paddingBottom='small'
            className='landing-page-hero'
        >

            {isNonWhitespaceString(video?.url) ?? (
                isNonWhitespaceString(title) && (
                    <div className='container'>
                        <h1 className={clsx(
                            'h1-hero title-top',
                            bgColor === 'red' && 'white'
                        )}>
                            {title}
                        </h1>
                    </div>
                )
            )}

            {isNonWhitespaceString(video?.url) ? (
                <div className='container'>
                    <Video
                        video={{
                            playButtonColor: 'red',
                            playText: video?.playText,
                            playTextColor: 'white',
                            videoType: video?.videoType,
                            poster: video?.poster, // 1250x700
                            posterMedium: video?.posterMedium, // 600x400
                            url: video?.url
                        }}
                    />
                </div>
            ) : (
                isNonWhitespaceString(image?.src) && (
                    <div className='full-width-wrapper'>
                        <Image
                            src={image?.src} // 1750x1000
                            srcMedium={image?.srcMedium} // 1000x570
                            srcSmall={image?.srcSmall} // 600x450
                            alt={image?.alt}
                            className='cover'
                        />
                    </div>
                )
            )}

            <div className={clsx(
                'container bottom-content',
                bgColor === 'red' && 'white'
            )}>
                {isNonWhitespaceString(video?.url) ? (
                    <div className='row'>
                        <div className='col-lg-10 offset-lg-1'>

                            {isNonWhitespaceString(title) && isNonWhitespaceString(content) ? (
                                <div className='row'>
                                    <div className='col-md-5 left'>
                                        <h1 className='h1-hero'>
                                            {title}
                                        </h1>
                                    </div>
                                    <div className='col-md-7 right'>
                                        <RichText
                                            content={content}
                                            className='text-big'
                                        />
                                    </div>
                                </div>
                            ) : isNonWhitespaceString(title) ? (
                                <div className='row'>
                                    <div className='col-12'>
                                        <h1 className='h1-hero title-center'>
                                            {title}
                                        </h1>
                                    </div>
                                </div>
                            ) : isNonWhitespaceString(content) ? (
                                <div className='row'>
                                    <div className='col-xl-10 offset-xl-1'>
                                        <RichText
                                            content={content}
                                            className='text-big content-center'
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <div className='row'>
                        <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                            <RichText
                                content={content}
                                className='text-big content-center'
                            />
                        </div>
                    </div>
                )}
            </div>

        </Section>
    )
}