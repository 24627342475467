//libraries
import React, { useEffect } from 'react'
import clsx from 'clsx'

// prop types
export interface PopupProps {
    title?: string
    children: React.ReactNode
    id: string
    className?: string,
    onCloseFn?: any
}

export default function Popup({
    title,
    children,
    id,
    className,
    onCloseFn
}: PopupProps) {

    // close the dialog
    const closeDialog = () => {
        const dialog = document.getElementById(id) as HTMLDialogElement

        if (dialog) {
            dialog.close()

            // Check if there are any other open dialogs
            const openDialogs = document.querySelectorAll('dialog[open]')
            
            if (openDialogs.length === 0) {
                document.body.classList.remove('no-scroll')
            }
        }

        if (onCloseFn) {
            onCloseFn()
        }
    }

    // prevent close menu when pressing ESC
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault()
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    })

    // check if the dialog is open
    useEffect(() => {
        const dialog = document.getElementById(id) as HTMLDialogElement
    
        if (dialog) {
            const observer = new MutationObserver((mutationsList) => {
                mutationsList.forEach((mutation) => {
                    if (mutation.attributeName === 'open') {
                        if (dialog.open) {
                            document.body.classList.add('no-scroll')
                        } else {
                            if (onCloseFn) {
                                onCloseFn()
                            }

                            const openDialogs = document.querySelectorAll('dialog[open]')

                            if (openDialogs.length === 0) {
                                document.body.classList.remove('no-scroll')
                            }
                        }
                    }
                })
            })
    
            observer.observe(dialog, { attributes: true })
    
            return () => {
                observer.disconnect()
                const openDialogs = document.querySelectorAll('dialog[open]')

                if (openDialogs.length === 0) {
                    document.body.classList.remove('no-scroll')
                }
            }
        }
    }, [id])    

    // add effect to handle elements with data-dialog-close
    useEffect(() => {
        const dialog = document.getElementById(id) as HTMLDialogElement
        const closeButtons = dialog?.querySelectorAll('[data-dialog-close]')

        const handleClick = (event: MouseEvent) => {
            const targetButton = event.currentTarget as HTMLElement
            const closestDialog = targetButton.closest('dialog') as HTMLDialogElement

            if (closestDialog && closestDialog.open) {
                closestDialog.close()

                const openDialogs = document.querySelectorAll('dialog[open]')

                if (openDialogs.length === 0) {
                    document.body.classList.remove('no-scroll')
                }
            }
        }

        closeButtons?.forEach(button => {
            button.addEventListener('click', handleClick)
        })

        return () => {
            closeButtons?.forEach(button => {
                button.removeEventListener('click', handleClick)
            })

            const openDialogs = document.querySelectorAll('dialog[open]')

            if (openDialogs.length === 0) {
                document.body.classList.remove('no-scroll')
            }
        }
    }, [id])

    // backdrop click
    const handleBackdropClick = (event: React.MouseEvent<HTMLDialogElement>) => {
        const dialogElement = event.currentTarget

        if (event.target === dialogElement) {
            closeDialog()
    
            const openDialogs = document.querySelectorAll('dialog[open]')

            if (openDialogs.length === 0) {
                document.body.classList.remove('no-scroll')
            }
        }
    }

    return (
        <dialog
            id={id}
            className={clsx('dash-popup', className)}
            //onClick={handleBackdropClick}
        >
            <div className='dash-popup__inner'>

                <div className='dash-popup__top'>

                    {title && (
                        <h3 className='h2'>
                            {title}
                        </h3>
                    )}

                    <button
                        data-dialog-close
                        className='close'
                    >
                        <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'><path d='M0.514695 0.514726C0.905219 0.124201 1.53838 0.124201 1.92891 0.514726L8.99998 7.5858L16.0711 0.514726C16.4616 0.124201 17.0947 0.124201 17.4853 0.514726C17.8758 0.90525 17.8758 1.53841 17.4853 1.92894L10.4142 9.00001L17.4853 16.0711C17.8758 16.4616 17.8758 17.0948 17.4853 17.4853C17.0947 17.8758 16.4616 17.8758 16.071 17.4853L8.99998 10.4142L1.92892 17.4853C1.5384 17.8758 0.905233 17.8758 0.514708 17.4853C0.124184 17.0948 0.124184 16.4616 0.514708 16.0711L7.58577 9.00001L0.514695 1.92894C0.124171 1.53841 0.124171 0.90525 0.514695 0.514726Z'></path></svg>
                    </button>

                </div>

                <div className='dash-popup__content'>
                    {children}
                </div>

            </div>
        </dialog>
    )
}