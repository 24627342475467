//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Image from '/react4xp/components/Image'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

// prop types
export interface LinkListProps {
    style?: 'block' | 'inline'
    icon?: string
    alt?: string
    text: string
    link: string
    isExternal?: boolean
}

export default function LinkList({
    style,
    icon,
    alt,
    text,
    link,
    isExternal
}: LinkListProps) {
    return (
        <a
            href={link}
            target={isExternal ? '_blank' : undefined}
            className={clsx(
                'link-list',
                style === 'block' && 'link-list--block',
                style === 'inline' && 'link-list--inline'
            )}
        >

            {style === 'inline' && (
                icon && (
                    <Image
                        src={icon}
                        alt={alt}
                        width={24}
                        className='icon'
                    />
                )
            )}

            {style === 'block' && (
                <div className='arrow'>
                    <UxArrowRight />
                </div>  
            )}

            {isNonWhitespaceString(text) && (
                <p className='text'>
                    {text}
                </p>
            )}

            {style === 'inline' && (
                <div className='arrow'>
                    <UxArrowRight />
                </div>  
            )}

        </a>
    )
}