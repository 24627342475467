// libraries
import React, { forwardRef } from 'react'
import clsx from 'clsx'

// components
import Fancybox from '/react4xp/components/Fancybox'
import Button from '/react4xp/components/Button'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils'

// svg
import { GeneralClose } from '/react4xp/components/Svg/Icons/General'

// prop types
interface PopupProps {
    size: 'small' | 'medium' | 'large'
    hasCloseButton?: boolean
    closeButtonText?: string
    title?: string
    content?: string
    actionButtons?: {
        primary?: {
            type?: 'submit' | 'button'
            text: string
            href?: string
            target?: boolean
            onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
            closeOnClick?: boolean
        }
        secondary?: {
            type?: 'submit' | 'button'
            text: string
            href?: string
            target?: boolean
            onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
            closeOnClick?: boolean
        }
    }
    onClose?: () => void
    className?: string
}

const Popup = forwardRef<HTMLAnchorElement, PopupProps>(({
    size,
    hasCloseButton,
    closeButtonText,
    title,
    content,
    actionButtons,
    onClose,
    className
}, ref) => {

    const id = 'id-' + Math.random().toString(36)

    return (
        <Fancybox
            options={{
                autoFocus: false,
                dragToClose: false,
                backdropClick: false,

                // since we cannot use onClick events inside fancybox 
                // we need this work around to bind the click events
                on: {
                    done: (_fancybox, slide) => {
                        const buttonPrimary = document.getElementById(id)?.querySelector('.popup__button-primary')
                        const buttonSecondary = document.getElementById(id)?.querySelector('.popup__button-secondary')

                        if (buttonPrimary) {
                            const primaryClickHandler = (e: MouseEvent) => {
                                actionButtons?.primary?.onClick?.(e as unknown as React.MouseEvent<HTMLButtonElement>)
                                buttonPrimary.removeEventListener('click', primaryClickHandler)
                            }
                            buttonPrimary.addEventListener('click', primaryClickHandler)
                        }

                        if (buttonSecondary) {
                            const secondaryClickHandler = (e: MouseEvent) => {
                                actionButtons?.secondary?.onClick?.(e as unknown as React.MouseEvent<HTMLButtonElement>)
                                buttonSecondary.removeEventListener('click', secondaryClickHandler)
                            }
                            buttonSecondary.addEventListener('click', secondaryClickHandler)
                        }
                    },
                    close: (_fancybox, slide) => {
                        onClose?.()
                    }
                }
            }}
        >
            <a
                ref={ref}
                href={'#' + id}
                data-fancybox
                style={{ position: 'absolute', visibility: 'hidden' }}
            ></a>

            <div
                id={id}
                className={clsx(
                    'popup',
                    size === 'small' && 'popup--small',
                    size === 'medium' && 'popup--medium',
                    size === 'large' && 'popup--large',
                    className
                )}
            >
                <div className='popup__wrapper'>
                    {hasCloseButton && (
                        <div className='popup__close'>
                            <button
                                className='popup__close-button link-w-icon link-w-icon--icon-right text-smaller'
                                type='button'
                                data-fancybox-close
                            >
                                {isNonWhitespaceString(closeButtonText) && (
                                    <span>{closeButtonText}</span>
                                )}
                                <GeneralClose variant='outline' />
                            </button>
                        </div>
                    )}

                    {isNonWhitespaceString(title) && (
                        <h2 className='h4 popup__title'>
                            {title}
                        </h2>
                    )}

                    {content && (
                        <p className='popup__content'>
                            {content}
                        </p>
                    )}

                    {actionButtons && (
                        <div className='popup__action-buttons'>
                            {actionButtons.primary && (
                                <Button
                                    type={actionButtons.primary.type}
                                    href={actionButtons.primary.href}
                                    target={actionButtons.primary.target}
                                    hover='blue'
                                    hasBorders
                                    noUnderline
                                    onClick={(e) => actionButtons.primary?.onClick?.(e as React.MouseEvent<HTMLButtonElement>)}
                                    data-fancybox-close={actionButtons.primary.closeOnClick ? true : undefined}
                                    className='popup__button-primary text-small'
                                >
                                    {actionButtons.primary.text}
                                </Button>
                            )}
                            {actionButtons.secondary && (
                                <Button
                                    type={actionButtons.secondary.type}
                                    href={actionButtons.secondary.href}
                                    target={actionButtons.secondary.target}
                                    color='transparent'
                                    onClick={(e) => actionButtons.secondary?.onClick?.(e as React.MouseEvent<HTMLButtonElement>)}
                                    data-fancybox-close={actionButtons.secondary.closeOnClick ? true : undefined}
                                    className='popup__button-secondary text-small'
                                >
                                    {actionButtons.secondary.text}
                                </Button>
                            )}

                        </div>
                    )}
                </div>
            </div>
        </Fancybox>
    )
})

export default Popup