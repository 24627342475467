// libraries
import React from 'react'

// props
interface CheckboxProps {
    id: string
    name: string
    required?: boolean
    disabled?: boolean
    checked?: boolean
    text?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function Checkbox({
    id,
    name,
    required,
    disabled,
    checked,
    text,
    onChange
}: CheckboxProps) {

    const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
        event.stopPropagation()
    }

    return (
        <div className='dash-checkbox-radio-wrapper'>

            <input
                type='checkbox'
                id={id}
                name={name}
                required={required}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
            />

            <label htmlFor={id} className='dash-checkbox-square' onClick={handleLabelClick}>
                <svg width='27' height='20' viewBox='0 0 27 20' xmlns='http://www.w3.org/2000/svg'><path d='M26.2363 1.24756C26.7637 1.8335 26.7637 2.7124 26.2363 3.23975L10.7676 18.7085C10.1816 19.2944 9.30273 19.2944 8.77539 18.7085L0.806641 10.7397C0.220703 10.2124 0.220703 9.3335 0.806641 8.80615C1.33398 8.22021 2.21289 8.22021 2.74023 8.80615L9.71289 15.7788L24.2441 1.24756C24.7715 0.720215 25.6504 0.720215 26.1777 1.24756H26.2363Z'></path></svg>
            </label>

            <label htmlFor={id} className='dash-checkbox-text' onClick={handleLabelClick}>
                {text}
            </label>
            
        </div>
    )
}