//libraries
import React from 'react'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface TransportCardsProps {
    section: {
        bgColor: 'red' | 'white' | 'white-dark'
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
    }
    content: Array<{
        src: string
        alt?: string
        title: string
        link: string
        external?: boolean
    }>
}

export default function TransportCards({
    section,
    content
}: TransportCardsProps) {
    return (
        <Section
            className='transport-cards'
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
        >
            <div className='container'>
                <div className='transport-cards__icons-grid'>
                    {content.map((item, index) => (
                        <a key={index} href={item.link} target={item.external ? '_blank' : null}>
                            <div className='bg-white block'>

                                {item.src && (
                                    <Image
                                        src={item.src}
                                        alt={item.alt}
                                        className='icon'
                                        width={60} // use 70x70
                                    />
                                )}

                                {isNonWhitespaceString(item.title) && (
                                    <h3 className='title h4'>
                                        {item.title}
                                    </h3>
                                )}

                                <UxArrowRight />

                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </Section>
    )
}