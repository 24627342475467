// libraries
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useFormContext, RegisterOptions } from 'react-hook-form'

// components
import Link from '/react4xp/components/Link'

// utils
import { limitCharacters } from '/lib/reactutils/utils.js'

// svg
import { GeneralTrash, GeneralWarning } from '/react4xp/components/Svg/Icons/General'

interface UploadProps {
    id: string
    name: string
    label?: string
    required?: boolean
    disabled?: boolean
    className?: string
    accept?: string
    multiple?: boolean
    maxFileSize?: number
    errorMessages?: {
        required?: string
        maxFileSize?: string
    }
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function Upload({
    id,
    name,
    label,
    required,
    disabled,
    className,
    accept,
    multiple,
    maxFileSize,
    errorMessages,
    onChange
}: UploadProps) {
    const [files, setFiles] = useState<File[]>([])

    const formContext = useFormContext()
    const register = formContext ? formContext.register : () => ({})
    const errors = formContext ? formContext.formState.errors : {}

    let validations: RegisterOptions = {
        //onChange: (e) => onChange(e),
        required
    }

    if (required) {
        validations.required = errorMessages?.required
    }

    const errorMessage = errors && errors[name]?.message

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const selectedFiles = event.target.files

        if (selectedFiles) {
            const MAX_FILE_SIZE = maxFileSize ? maxFileSize * 1024 * 1024 : 10 * 1024 * 1024

            const newFiles: File[] = []

            for (let i = 0; i < selectedFiles.length; i++) {
                const file = selectedFiles[i]

                if (file.size > MAX_FILE_SIZE) {
                    alert(`${errorMessages?.maxFileSize} ${maxFileSize}mb.`)
                    return
                }

                // only add the file if it's not already in the state
                if (!files.some(existingFile => existingFile.name === file.name)) {
                    newFiles.push(file)
                }
            }

            // append the new files to the existing ones
            setFiles(prevFiles => [...prevFiles, ...newFiles])

            const fileInput = document.getElementById(id) as HTMLInputElement
            const newFilesDT = new DataTransfer()
            if (fileInput) {
                ((files || []).concat(newFiles)).forEach(f => {
                    newFilesDT.items.add(f)
                })
                fileInput.files = newFilesDT.files
            }

            if (onChange) {
                onChange(event)
            }
        }
    }

    const handleRemoveFile = (fileNameToRemove: string) => {
        const updatedFiles = files.filter(file => file.name !== fileNameToRemove)
        setFiles(updatedFiles)

        const fileInput = document.getElementById(id) as HTMLInputElement

        if (fileInput) {
            const newFiles = new DataTransfer()

            Array.from(fileInput.files).forEach((file) => {
                if (file.name != fileNameToRemove) {
                    newFiles.items.add(file)
                }
            })

            fileInput.files = newFiles.files
        }
    }

    useEffect(() => {
        const handleResetForm = () => {
            setFiles([])
        }

        document.addEventListener('resetForm', handleResetForm)

        return () => {
            document.removeEventListener('resetForm', handleResetForm)
        }
    }, [setFiles])

    return (
        <div className={clsx(
            'form-line',
            className,
            errors && errors[name] && 'form-line--error'
        )}>

            <label className={clsx('form-line__label text-small')} htmlFor={id}>
                {label}
            </label>

            <div className='form-line__line-wrapper'>

                <input
                    type='file'
                    id={id}
                    name={name}
                    className='form-line__input form-line__input--file'
                    disabled={disabled || false}
                    accept={accept}
                    multiple={multiple}
                    {...(register ? register(name, validations) : {})}
                    onChange={handleFileChange}
                />

                <label
                    htmlFor={id}
                    className='button button-small-borders button-has-borders bg-transparent no-underline hover-bg-black'
                >
                    Velg fil
                </label>

                {files.length ? (
                    <div className='form-line__file-list'>
                        
                        {files.map((file, i) => (
                            <div
                                className='form-line__remove-file'
                                key={i}
                                onClick={() => handleRemoveFile(file.name)}
                            >
                                <p className='file-name text-small' key={i}>
                                    {limitCharacters(file.name, 17)}
                                </p>
                                <Link
                                    text='Fjern bilde'
                                    type='button'
                                    onClick={() => handleRemoveFile(file.name)}
                                    icon={<GeneralTrash variant='outline' />}
                                    iconPosition='left'
                                />

                            </div>
                        ))}
                    </div>
                ) : null}

            </div>

            {errorMessage && (
                <p className={clsx('form-line__error-message', 'text-smaller')}>
                    <GeneralWarning />
                    {typeof errorMessage === 'string' && errorMessage}
                </p>
            )}

        </div>
    )
}