// libraries
import React from 'react'

// props
interface TextAreaProps {
    id: string
    name: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    value?: string
    defaultValue?: string
    onFocus?: () => void
    onBlur?: () => void
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export default function TextArea({
    id,
    name,
    placeholder,
    required,
    disabled,
    value,
    defaultValue,
    onFocus,
    onBlur,
    onChange
}: TextAreaProps) {
    return (
        <textarea
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            className='dash-textarea'
            value={value}
            defaultValue={defaultValue}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
        ></textarea>
    )
}