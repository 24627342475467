// libraries
import React from 'react'
import clsx from 'clsx'
import Barcode from 'react-barcode'

// components
import Image from '/react4xp/components/Image'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
interface ProductCardBarcodeProps {
    bgColor?: 'white' | 'white-dark'
    src?: string
    srcMedium?: string
    alt?: string
    width?: number
    sizes?: string
    title?: string
    quantity?: string
    barcode?: string
    className?: string
}

export default function ProductCardBarcode({
    bgColor = 'white',
    src,
    srcMedium,
    alt,
    width,
    sizes,
    title,
    quantity,
    barcode,
    className,
    ...rest
}: ProductCardBarcodeProps) {
    return (
        <div
            className={clsx(
                'product-card',
                'product-card--barcode',
                className
            )}
            {...rest}
        >
            <div
                className={clsx(
                    'product-card__mask',
                    bgColor === 'white' && 'bg-white',
                    bgColor === 'white-dark' && 'bg-white-dark'
                )}
                style={{ backgroundColor: bgColor }}
            >

                {src && (
                    <div className='product-card__image'>
                        <Image
                            src={src}
                            srcMedium={srcMedium}
                            alt={alt}
                            width={width}
                            sizes={sizes}
                        />
                    </div>
                )}

                <div className='product-card__wrapper'>

                    {isNonWhitespaceString(title) && (
                        <h3 className='product-card__wrapper__title h4'>
                            {title}
                        </h3>  
                    )}

                    {isNonWhitespaceString(quantity) && (
                        <p className='product-card__wrapper__quantity'>
                            {quantity}
                        </p>  
                    )}

                    {barcode && (
                        <div className='product-card__wrapper__barcode'>
                            <Barcode format='ITF' value={barcode} height={50} fontSize={25} />
                        </div>
                    )}
                    
                </div>
            </div>

        </div>
    )
}