// libraries
import React from 'react'
import clsx from 'clsx'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
interface MacroQuoteProps {
    howMany: 1 | 2
    firstQuote: {
        quote: string
        author?: string
        className?: string
    }
    secondQuote?: {
        quote: string
        author?: string
        className?: string
    }
    className?: string
}

export default function MacroQuote({
    howMany,
    firstQuote,
    secondQuote,
    className
}: MacroQuoteProps) {
    return (

        howMany === 1 ? (
            <div className={clsx('macros--quote macros--quote--one', className)}>
                <div className='row'>
                    <div className='col-12'>
                        <div className='macros--quote__block'>

                            {isNonWhitespaceString(firstQuote.quote) && (
                                <div
                                    className='text-big italic'
                                    dangerouslySetInnerHTML={{ __html: firstQuote.quote }}
                                    suppressHydrationWarning
                                />
                            )}

                            {isNonWhitespaceString(firstQuote.author) && (
                                
                                <p className='text-small macros--quote__author'>
                                    {firstQuote.author}
                                </p>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={clsx('macros--quote macros--quote--two', className)}>
                <div className='row'>

                    <div className='col-md-6'>
                        <div className='macros--quote__block'>

                            {isNonWhitespaceString(firstQuote.quote) && (
                                <div
                                    className='text-big italic'
                                    dangerouslySetInnerHTML={{ __html: firstQuote.quote }}
                                    suppressHydrationWarning
                                />
                            )}

                            {isNonWhitespaceString(firstQuote.author) && (
                                <p className='text-small macros--quote__author'>
                                    {firstQuote.author}
                                </p>
                            )}

                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='macros--quote__block macros--quote'>

                            {isNonWhitespaceString(secondQuote.quote) && (
                                <div
                                    className='text-big italic'
                                    dangerouslySetInnerHTML={{ __html: secondQuote.quote }}
                                    suppressHydrationWarning
                                />
                            )}

                            {isNonWhitespaceString(secondQuote.author) && (
                                <p className='text-small macros--quote__author'>
                                    {secondQuote.author}
                                </p>
                            )}

                        </div>
                    </div>

                </div>
            </div>
        )
    )
}