// libraries
import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useFormContext, useWatch, RegisterOptions } from 'react-hook-form'

// svg
import { GeneralWarning } from '/react4xp/components/Svg/Icons/General'

interface TextAreaProps {
    id: string
    name: string
    label?: string
    placeholder?: string
    required?: boolean
    maxLength?: {
        limit: number
        text: string
    }
    minLength?: number
    value?: string
    disabled?: boolean
    size?: 'small' | 'medium' | 'large' | 'full-width'
    isWhite?: boolean
    className?: string
    errorMessages?: {
        required?: string
        maxLength?: string
        minLength?: string
    }
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
}

export default function TextArea({
    id,
    name,
    label,
    placeholder,
    required,
    maxLength,
    minLength,
    value = '',
    disabled,
    size = 'full-width',
    isWhite,
    className,
    errorMessages,
    onChange,
    onKeyDown
}: TextAreaProps) {
    const { register, setValue, control, formState: { errors } } = useFormContext()

    let validations: RegisterOptions = {
        onChange: (e) => {
            setValue(name, e.target.value)
            if (onChange) onChange(e)
        }
    }

    if (required) {
        validations.required = errorMessages?.required
    }

    if (maxLength) {
        validations.maxLength = {
            value: maxLength.limit,
            message: errorMessages?.maxLength
        }
    }

    if (minLength) {
        validations.minLength = {
            value: minLength,
            message: errorMessages?.minLength
        }
    }

    useEffect(() => {
        if (value) {
            setValue(name, value)
        }
    }, [name, setValue, value])

    const watchedValue = useWatch({
        control,
        name,
        defaultValue: value,
    })

    const characterCount = watchedValue ? watchedValue.length : 0
    const errorMessage = errors[name]?.message

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && onKeyDown) {
            onKeyDown(event)
        }
    }

    return (
        <div
            className={clsx(
                'form-line',
                className,
                size === 'small' && 'form-line--small',
                size === 'medium' && 'form-line--medium',
                size === 'large' && 'form-line--large',
                size === 'full-width' && 'form-line--full-width',
                errors[name] && 'form-line--error',
                isWhite && 'white'
            )}
        >
            <label className='form-line__label text-small' htmlFor={id}>
                {label}
            </label>

            <div className='form-line__line-wrapper'>

                <textarea
                    {...register(name, validations)}
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    className='form-line__input form-line__textarea text-small'
                    disabled={disabled || false}
                    maxLength={maxLength.limit}
                    minLength={minLength}
                    onKeyDown={handleKeyPress}
                ></textarea>

                {maxLength && (
                    <p className='form-line__counter'>
                        {characterCount}/{maxLength.limit} {maxLength.text}
                    </p>
                )}

            </div>

            {errorMessage && (
                <p className='form-line__error-message text-smaller'>
                    <GeneralWarning />
                    {typeof errorMessage === 'string' && errorMessage}
                </p>
            )}

        </div>
    )
}
