//libraries
import React from 'react'

// components
import Section from '/react4xp/components/Section'
import Accordion from '/react4xp/components/Accordion'
import RichText from '/react4xp/components/RichText'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface FaqSectionProps {
    section?: {
        bgColor?: string
        paddingTop?: 'smaller' | 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'smaller' | 'small' | 'medium' | 'big' | 'none'
    }
    maskColor?: 'white' | 'white-dark'
    title?: string
    desc?: string
    isRed?: boolean
    accordion?: Array<{
        question: string
        answer: string
    }>
}

export default function FaqSection({
    section,
    title,
    desc,
    accordion,
    isRed
}: FaqSectionProps) {
    return (
        <Section
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            className='faq-section'
        >
            <div className='container'>
                <div className='row'>

                    {isNonWhitespaceString(desc) ? (
                        <>

                            <div className='col-lg-6 left'>

                                {isNonWhitespaceString(title) && (
                                    <h2 className='h2'>
                                        {title}
                                    </h2>  
                                )}

                                {isNonWhitespaceString(desc) && (
                                    <RichText content={desc} />
                                )}

                            </div>

                            <div className='col-lg-6 right'>
                                { accordion?.map((item, index) => (
                                    <Accordion
                                        key={index}
                                        question={item.question}
                                        answer={item.answer}
                                        isRed={isRed}
                                    />
                                ))}
                            </div>

                        </>
                    ) : (
                        <div className='col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 center'>

                            {isNonWhitespaceString(title) && (
                                <h2 className='h2'>
                                    {title}
                                </h2>  
                            )}

                            { accordion?.map((item, index) => (
                                <Accordion
                                    key={index}
                                    question={item.question}
                                    answer={item.answer}
                                    isRed={isRed}
                                />
                            ))}

                        </div>
                    )}

                </div>
            </div>
        </Section>
    )
}