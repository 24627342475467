// libraries
import React from 'react'
import clsx from 'clsx'
import { useFormContext, RegisterOptions } from 'react-hook-form'

// svg
import { GeneralCheck, GeneralWarning } from '/react4xp/components/Svg/Icons/General'

interface CheckboxProps {
    id?: string
    name: string
    required?: boolean
    disabled?: boolean
    checked?: boolean
    children?: React.ReactNode
    className?: string
    errorMessages?: {
        required?: string
    }
}

export default function Checkbox({
    id,
    name,
    required,
    disabled,
    checked,
    children,
    errorMessages,
    className
}: CheckboxProps) {

    const formContext = useFormContext()
    const register = formContext ? formContext.register : () => ({})
    const errors = formContext ? formContext.formState.errors : {}

    let validations: RegisterOptions = {}

    if (required) {
        validations.required = errorMessages?.required
    }

    const errorMessage = errors && errors[name]?.message

    return (
        <div className={clsx(
            'form-line',
            className,
            errors && errors[name] && 'form-line--error'
        )}>

            <label className={clsx('form-line__checkbox-wrapper text-small')} htmlFor={id}>

                <input
                    {...(register ? register(name || '', validations) : {})}
                    type='checkbox'
                    id={id}
                    className='form-line__input form-line__input--checkbox text-small'
                    defaultChecked={checked}
                    disabled={disabled || false}
                />

                <div className='form-line__checkbox'>
                    <GeneralCheck variant='outline' />
                </div>

                <div className='text-small'>
                    {children}
                </div>

            </label>

            {errorMessage && (
                <p className={clsx('form-line__error-message', 'text-smaller')}>
                    <GeneralWarning />
                    {typeof errorMessage === 'string' && errorMessage}
                </p>
            )}

        </div>
    )
}