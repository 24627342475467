// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'
import Link from '/react4xp/components/Link'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
interface ContactCardProps {
    section: {
        bgColor: 'white' | 'white-dark' | 'yellow-light' | 'red-light'
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
    }
    image: {
        redOutline?: boolean
        src: string
        alt?: string
    }
    title?: string
    position?: string
    links?: Array<{
        text: string
        href: string
        external?: boolean
    }>
}

export default function ContactCard({
    section,
    image,
    title,
    position,
    links
}: ContactCardProps) {
    return (
        <Section
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            className='contact-card'
        >
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 offset-lg-1'>
                        <div className='row inner-row'>

                            <div className='col-md-6 contact-card__image'>
                                {image.src && (
                                    <div className={clsx(
                                        'image-box',
                                        image.redOutline && 'image-box--red-outline'
                                    )}>
                                        <Image
                                            src={image.src} // 500x450
                                            alt={image.alt}
                                            width={550}
                                            className='cover'
                                        />
                                    </div>
                                )}
                            </div>

                            <div className='col-md-6 contact-card__content'>

                                {isNonWhitespaceString(title) && (
                                    <h2 className='h3'>
                                        {title}
                                    </h2>
                                )}

                                {isNonWhitespaceString(position) && (
                                    <p className='text-big position'>
                                        {position}
                                    </p>
                                )}

                                {links?.map((item, i) => (
                                    <Link
                                        key={i}
                                        text={item.text}
                                        href={item.href}
                                        target={item.external}
                                    />
                                ))}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}