// libraries
import React from 'react'
import clsx from 'clsx'

// prop types
export interface SectionProps {
    children: React.ReactNode
    bgColor?: 'white' | 'white-dark' | 'blue-light' | 'blue' | 'yellow-light' | 'red' | 'red-light' | string
    paddingTop?: 'smaller' | 'small' | 'medium' | 'big' | 'none'
    paddingBottom?: 'smaller' | 'small' | 'medium' | 'big' | 'none'
    className?: string
    id?: string
}

export default function Section({
    bgColor,
    paddingTop,
    paddingBottom,
    children,
    className,
    id
}: SectionProps) {

    // custom bg color
    const customBgColor = bgColor && !['white', 'white-dark', 'blue-light', 'blue', 'yellow-light', 'red', 'red-light'].includes(bgColor) ? { backgroundColor: bgColor } : {}

    return (
        <section id={id} className={clsx('section', className )}>
            <div
                className={clsx(
                    'section__wrapper',

                    // paddings
                    paddingTop === 'smaller' && 'padding--top-smaller',
                    paddingTop === 'small' && 'padding--top-small',
                    paddingTop === 'medium' && 'padding--top-medium',
                    paddingTop === 'big' && 'padding--top-big',
                    paddingTop === 'none' && 'padding--top-none',
                    paddingBottom === 'smaller' && 'padding--bottom-smaller',
                    paddingBottom === 'small' && 'padding--bottom-small',
                    paddingBottom === 'medium' && 'padding--bottom-medium',
                    paddingBottom === 'big' && 'padding--bottom-big',
                    paddingBottom === 'none' && 'padding--bottom-none',

                    // bg colors
                    bgColor === 'white' && 'bg-white',
                    bgColor === 'white-dark' && 'bg-white-dark',
                    bgColor === 'blue-light' && 'bg-blue-light',
                    bgColor === 'blue' && 'bg-blue',
                    bgColor === 'yellow-light' && 'bg-yellow-light',
                    bgColor === 'red' && 'bg-red',
                    bgColor === 'red-light' && 'bg-red-light',
                )}
                style={customBgColor}
            >
                <div className='relative z2'>
                    {children}
                </div>
            </div>
        </section>
    )
}