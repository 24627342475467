// libraries
import React, { useEffect, useState } from 'react'

// props
interface InputProps {
    type: 'text' | 'email' | 'password' | 'tel' | 'date'
    id: string
    name: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    min?: string
    max?: string
    value?: string
    onFocus?: () => void
    onBlur?: () => void
    onKeyDown?: (event) => void
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    errorMessages?: {
        maxHigherThanToday?: string
    }
}

export default function Input({
    type,
    id,
    name,
    placeholder,
    required,
    disabled,
    min,
    max,
    value,
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
    errorMessages
}: InputProps) {

    const [isIOS, setIsIOS] = useState(false)
    const today = new Date().toISOString().split('T')[0]

    useEffect(() => {
        // detect iOS devices
        const userAgent = window.navigator.userAgent
        const isIOSDevice = /iPhone|iPad|iPod/i.test(userAgent)
        setIsIOS(isIOSDevice)
    }, [])

    // handler to check and adjust the selected date if it exceeds today's date
    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = event.target.value

        // if the type is date and max isn't defined, check against today's date
        if (type === 'date' && !max && selectedDate > today) {

            // enforce the max date
            event.target.value = today

            // alert the user that the date has changed
            errorMessages?.maxHigherThanToday && alert(errorMessages.maxHigherThanToday)
        }

        // call the onChange handler if provided
        if (onChange) {
            onChange(event)
        }
    }

    return (
        <input
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            min={min}
            max={type === 'date' && !max ? today : max}
            className='dash-input'
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onChange={type === 'date' ? handleDateChange : onChange}
        />
    )
}