// libraries
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

// components
import Image from '/react4xp/components/Image'
import Button from '/react4xp/components/Dashboard/Button'
import Guidelines from '/react4xp/components/Guidelines'

// utils
import { getFocusableElementsOutside } from '/lib/reactutils/utils'

// svg
import { GeneralPerson } from '/react4xp/components/Svg/Icons/General'

// props
interface HeaderProps {
    isPrototype?: boolean
    logo?: {
        src: string
        href: string
        title: string
        alt?: string
    }
    menu?: {
        menuText: string
        userName?: string
        backToHomeText: string
        logout: {
            text: string
            onClick?: (e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>) => void
        }
        items: Array<{
            name: string,
            href: string,
            external?: boolean
        }>
    }
    pageTitle?: string
    mainPageUrl?: string
}

export default function Header({
    isPrototype,
    logo,
    menu,
    pageTitle,
    mainPageUrl
}: HeaderProps) {

    // open / close fs menu
    const [isShown, setIsShown] = useState(false)

	const openCloseMenu = () => {
		setIsShown(!isShown)
	}

    const closeMenu = () => {
        setIsShown(false)
    }

    useEffect(() => {
        document.documentElement.classList.add('page-dashboard')
    }, [])

    useEffect(() => {
        if (!menu) return
    
        const focusable_els = document.getElementById('trap-focus')
        if (!focusable_els) return
    
        const focusableElementsOutside = getFocusableElementsOutside(focusable_els)
    
        const disableFocusOutside = () => {
            focusableElementsOutside.forEach((el) => {
                el.setAttribute('tabindex', '-1')
            })
        }
    
        const enableFocusOutside = () => {
            focusableElementsOutside.forEach((el) => {
                el.removeAttribute('tabindex')
            })
        }
    
        if (isShown) {
            disableFocusOutside()
            document.body.style.overflowY = 'hidden'
        } else {
            enableFocusOutside()
            document.body.style.overflowY = 'auto'
        }
    }, [isShown, menu])

    // close menu when pressing ESC
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setIsShown(false)
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    })

    return (
        <div className='page-dashboard__header'>

            {isPrototype && <Guidelines />}

            {logo && menu && (
                <section className='dashboard-top-menu'>
                    <div className='dashboard-grid-container'>

                        {logo && (
                            <div className='dashboard-grid-container__left'>
                                <a href={logo.href} className='logo'>
                                    <Image
                                        src={logo.src}
                                        width={150}
                                        alt={logo.alt}
                                    />
                                </a>
                            </div>
                        )}

                        {menu && (
                            <div className='dashboard-grid-container__right'>

                                <div className='floating-menu'>
                                    <Button
                                        style='hollow'
                                        size='medium'
                                        onClick={() => setIsShown(true)}
                                    >
                                        {menu.menuText}
                                        <svg width='60' height='37' viewBox='0 0 60 37' xmlns='http://www.w3.org/2000/svg'><path d='M0 1.5C0 0.671573 0.671573 0 1.5 0H58.5C59.3284 0 60 0.671573 60 1.5C60 2.32843 59.3284 3 58.5 3H1.5C0.671571 3 0 2.32843 0 1.5Z M0 35.5C0 34.6716 0.671573 34 1.5 34H58.5C59.3284 34 60 34.6716 60 35.5C60 36.3284 59.3284 37 58.5 37H1.5C0.671571 37 0 36.3284 0 35.5Z M0 18.5C0 17.6716 0.671573 17 1.5 17H58.5C59.3284 17 60 17.6716 60 18.5C60 19.3284 59.3284 20 58.5 20H1.5C0.671571 20 0 19.3284 0 18.5Z'/></svg>
                                    </Button>
                                </div>

                                <h1 className='dash-h1'>
                                    {pageTitle}
                                </h1>
                                
                            </div>
                        )}

                    </div>
                </section>
            )}

            {menu && (
                <>

                    <section
                        className={clsx(
                            'dashboard-fs-menu',
                            isShown && 'dashboard-fs-menu--open'
                        )}
                        style={{transform: 'translateX(150%)'}}
                        id='trap-focus'
                    >
                        <div className='wrapper'>

                            <div className='top'>

                                <h2 className='dash-h4 username'>
                                    {menu.userName}
                                </h2>

                                <button onClick={closeMenu} className='close'>
                                    <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'><path d='M0.514695 0.514726C0.905219 0.124201 1.53838 0.124201 1.92891 0.514726L8.99998 7.5858L16.0711 0.514726C16.4616 0.124201 17.0947 0.124201 17.4853 0.514726C17.8758 0.90525 17.8758 1.53841 17.4853 1.92894L10.4142 9.00001L17.4853 16.0711C17.8758 16.4616 17.8758 17.0948 17.4853 17.4853C17.0947 17.8758 16.4616 17.8758 16.071 17.4853L8.99998 10.4142L1.92892 17.4853C1.5384 17.8758 0.905233 17.8758 0.514708 17.4853C0.124184 17.0948 0.124184 16.4616 0.514708 16.0711L7.58577 9.00001L0.514695 1.92894C0.124171 1.53841 0.124171 0.90525 0.514695 0.514726Z'></path></svg>
                                </button>

                            </div>

                            <a href={mainPageUrl || '#'} className='back-to-home'>

                                <svg width='29' height='30' viewBox='0 0 29 30' xmlns='http://www.w3.org/2000/svg'><path d='M6.499 9.20833L10.1973 12.9052L8.13525 14.9687L0.916504 7.75L8.13525 0.53125L10.1973 2.59479L6.499 6.29167H16.9582C20.0524 6.29167 23.0198 7.52083 25.2077 9.70875C27.3957 11.8967 28.6248 14.8641 28.6248 17.9583C28.6248 21.0525 27.3957 24.02 25.2077 26.2079C23.0198 28.3958 20.0524 29.625 16.9582 29.625H3.83317V26.7083H16.9582C19.2788 26.7083 21.5044 25.7865 23.1454 24.1455C24.7863 22.5046 25.7082 20.279 25.7082 17.9583C25.7082 15.6377 24.7863 13.4121 23.1454 11.7711C21.5044 10.1302 19.2788 9.20833 16.9582 9.20833H6.499Z'/></svg>
                                
                                <span>
                                    {menu.backToHomeText}
                                </span>
                                
                            </a>

                            {
                                menu.items && (menu.items.length > 0) &&
                                <ul className='items h3'>
                                    {menu.items.map((item, index) => (
                                        <li key={index}>
                                            <a href={item.href} target={item.external ? '_blank' : undefined}>
                                                <span className='circle'></span>
                                                <span>{item.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            }

                            <Button
                                size='small'
                                style='solid'
                                onClick={menu.logout.onClick}
                                className='logout-button'
                            >
                                {menu.logout.text}
                            </Button>
                            
                        </div>
                    </section>

                    <div
                        className={clsx(
                            'dashboard-fs-menu--shadow',
                            isShown && 'dashboard-fs-menu--shadow--open'
                        )}
                        onClick={closeMenu}
                    ></div>

                </>
            )}

        </div>
    )
}