//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'
import Button from '/react4xp/components/Button'
import Video from '/react4xp/components/Video'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface CampaignHeroProps {
    bgColor?: string
    title?: string
    date?: string
    fullWidth?: boolean
    image?: {
        src: string
        srcMedium: string
        srcSmall: string
        alt?: string
    }
    video?: {
        videoType: 'youtube' | 'vimeo' | 'default'
        playText: string
        poster?: string
        posterMedium?: string
        url: string
    }
    content?: string
    button?: {
        text?: string
        href: string
        external?: boolean
    }
}

export default function CampaignHero({
    bgColor,
    title,
    date,
    fullWidth,
    image,
    video,
    content,
    button,
}: CampaignHeroProps) {
    return (
        <Section
            bgColor={bgColor}
            paddingTop='small'
            className='campaign-hero'
        >

            {isNonWhitespaceString(title) && (
                <div className='campaign-hero__top white'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                                
                                <h1 className='h1-hero'>
                                    {title}
                                </h1>

                                {isNonWhitespaceString(date) && (
                                    <p className='date h4'>
                                        {date}
                                    </p>                                    
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className='campaign-hero__media'>
                <div className={clsx(fullWidth ? 'full-width' : 'container')}>
                    <div className='wrapper'>
                        {isNonWhitespaceString(video?.url) ? (
                            <Video
                                video={{
                                    playButtonColor: bgColor,
                                    playText: video?.playText,
                                    playTextColor: 'white',
                                    videoType: video?.videoType,
                                    poster: video?.poster,
                                    posterMedium: video?.posterMedium,
                                    url: video?.url
                                }}
                            />
                        ) : (
                            isNonWhitespaceString(image?.src) && (
                                <Image
                                    src={image?.src}
                                    srcMedium={image?.srcMedium}
                                    srcSmall={image?.srcSmall}
                                    alt={image?.alt}
                                    className='cover'
                                />
                            )
                        )}
                    </div>
                </div>
            </div>

            <div className='campaign-hero__bottom'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-10 col-md-8 offset-md-1 col-lg-6 offset-lg-2'>

                            {isNonWhitespaceString(content) && (
                                <div
                                    className='text-big rich-text white'
                                    dangerouslySetInnerHTML={{ __html: content }}
                                    suppressHydrationWarning
                                />  
                            )}

                            {isNonWhitespaceString(button.text) && (
                                <Button
                                    href={button.href}
                                    hover='blue'
                                    noUnderline
                                    target={!!button.external}
                                    className='bottom__button'
                                >
                                    {button.text}
                                </Button>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}