// libraries
import React from 'react'
import clsx from 'clsx'

// svg
import { UxAngleRight } from '/react4xp/components/Svg/Ux/Angle'

// prop types
interface BreadcrumbsProps {
    className?: string
    breadcrumbs: Array<{ name: string, href?: string }>
}

export default function Breadcrumbs({ className, breadcrumbs }: BreadcrumbsProps) {
    return (
        <nav
            className={clsx(
                'breadcrumbs',
                className
            )}
            aria-label='Breadcrumbs'
        >
            <ul
                itemScope
                itemType='http://schema.org/BreadcrumbList'
                className='text-smaller'
            >
                {breadcrumbs.map((item, i) => (
                    <span key={i}>
                        <li
                            itemProp='itemListElement'
                            itemScope
                            itemType='http://schema.org/ListItem'
                        >
                            {item.href ? (
                                <>
                                    <a
                                        href={item.href}
                                        className='regular-link'
                                        itemProp='item'
                                    >
                                        <span itemProp='name'>
                                            {item.name}
                                        </span>
                                    </a>

                                    <meta
                                        itemProp='position'
                                        content={`${i+1}`}
                                    />
                                </>
                            ) : (
                                <>
                                    <p
                                        itemProp='item'
                                    >
                                        <span itemProp='name'>
                                            {item.name}
                                        </span>
                                    </p>

                                    <meta
                                        itemProp='position'
                                        content={`${i+1}`}
                                    />

                                    <meta
                                        itemProp='currentpage'
                                        content='true'
                                    />
                                </>
                            )}
                        </li>

                        <li className='sep'>
                            <UxAngleRight />
                        </li>

                    </span>
                ))}
                
            </ul>
        </nav>
    )
}