// libraries
import React from 'react'

// components
import Section from '/react4xp/components/Section'

// props
interface FooterProps {
    logoSvg: string
    logoHref: string
    logoAriaLabel: string
    companyName?: string
    contact?: Array<{
        title: string
        text: string
    }>
    address?: Array<{
        title: string
        text: string
    }>
    socialLinks?: Array<{
        name: string
        href: string
    }>
    bottomLinks?: Array<{
        name: string
        href: string
        external?: boolean
    }>
    copyright?: string
}

export default function Footer({
    logoSvg,
    logoHref,
    logoAriaLabel,
    companyName,
    contact,
    address,
    socialLinks,
    bottomLinks,
    copyright,
}: FooterProps) {
    return (
        <Section
            id='footer'
            bgColor='white'
            paddingTop='medium'
            paddingBottom='medium'
        >
            <div className='container'>
                
                <div className='top'>
                    <a
                        href={logoHref}
                        aria-label={logoAriaLabel}
                        className='logo'
                        dangerouslySetInnerHTML={{ __html: logoSvg }}
                        suppressHydrationWarning
                    />
                </div>

                <div className='middle'>

                    <p className='h4'>
                        {companyName}
                    </p>

                    <div className='flex'>

                        <div className='left'>

                            {contact && (
                                <ul>
                                    {contact.map((item, i) => (
                                        <li key={i}>
                                            <b>{item.title}:</b><br />
                                            <a
                                                href={`tel:${item.text}`}
                                                className='regular-link'
                                            >
                                                {item.text}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            
                            {address && (
                                <ul>
                                    {address.map((item, i) => (
                                        <li key={i} className='address'>
                                            
                                            <b>
                                                {item.title}:
                                            </b><br />
                                            
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item.text }}
                                                suppressHydrationWarning
                                            />

                                        </li>
                                    ))}
                                </ul>
                            )}

                        </div>

                        {socialLinks && (
                            <ul className='social'>
                                {socialLinks.map((item, index) => (
                                    <li key={index}>
                                        <a href={item.href} target='_blank' className='regular-link'>
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>

                </div>

                {(bottomLinks && bottomLinks.length > 0) || copyright ? (
                    <div className='bottom'>

                        {bottomLinks && bottomLinks.length > 0 && (
                            <ul>
                                {bottomLinks.map((link, index) => (
                                    <li key={index}>
                                        <a href={link.href} className='regular-link' target={link.external ? '_blank' : undefined}>
                                            {link.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {copyright && (
                            <p className='text-small'>
                                {copyright}
                            </p>
                        )}

                    </div>
                ) : null}

            </div>
        </Section>
    )
}