//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import RichText from '/react4xp/components/RichText'
import Image from '/react4xp/components/Image'
import Link from '/react4xp/components/Link'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface ShareYourIdeaProps {
    section: {
        bgColor: 'white' | 'white-dark'
        paddingTop?: 'smaller' | 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'smaller' | 'small' | 'medium' | 'big' | 'none'
    }
    box: {
        image?: string
        alt?: string
        content?: string
        cta?: {
            text: string
            href: string
            external?: boolean
        }
    }
    
    className?: string
}

export default function ShareYourIdea({
    section,
    box,
    className
}: ShareYourIdeaProps) {
    return (
        <Section
            bgColor={section.bgColor}
            className={clsx('share-your-idea', className)}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
        >
            <div className='container relative z2'>
                <div className='share-your-idea__box white bg-red'>

                    {box.image && (
                        <Image
                            src={box.image}
                            alt={box.alt}
                            className='logo'
                        />
                    )}

                    {isNonWhitespaceString(box.content) && (
                        <RichText className='text-big' content={box.content} />
                    )}

                    {isNonWhitespaceString(box.cta?.text) && isNonWhitespaceString(box.cta?.href) && (
                        <Link
                            text={box.cta.text}
                            href={box.cta.href}
                            target={!!box.cta.external}
                            iconPosition='right'
                            icon={<UxArrowRight />}
                            isWhite={true}
                            noMotionMobile
                        />
                    )}

                </div>
            </div>
        </Section>
    )
}