// libraries
import React from 'react'

// props
interface SelectProps {
    id: string
    name: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    value?: string
    onFocus?: () => void
    onBlur?: () => void
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    children?: React.ReactNode
    defaultValue?: string
}

export default function Select({
    id,
    name,
    required,
    disabled,
    onFocus,
    onBlur,
    onChange,
    children,
    defaultValue
}: SelectProps) {
    return (
        <div className='dash-select'>

            <select
                id={id}
                name={name}
                required={required}
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                defaultValue={defaultValue}
            >
                {children}
            </select>

            <svg width='22' height='11' viewBox='0 0 22 11' xmlns='http://www.w3.org/2000/svg'><path d='M11.8804 10.5936L21.5467 1.88578C21.7081 1.74049 21.7981 1.54745 21.7981 1.34672C21.7981 1.14598 21.7081 0.952951 21.5467 0.807657L21.5358 0.798282C21.4576 0.727602 21.3634 0.671321 21.259 0.632862C21.1547 0.594403 21.0423 0.574571 20.9287 0.574571C20.8151 0.574571 20.7027 0.594403 20.5983 0.632862C20.494 0.671321 20.3998 0.727602 20.3216 0.798282L11.2196 8.99828L2.12135 0.798282C2.04312 0.727602 1.94895 0.671321 1.84459 0.632862C1.74022 0.594403 1.62782 0.574571 1.51425 0.574571C1.40067 0.574571 1.28827 0.594403 1.1839 0.632862C1.07954 0.671321 0.985374 0.727602 0.907146 0.798282L0.896223 0.807657C0.734856 0.952951 0.644835 1.14598 0.644835 1.34672C0.644835 1.54745 0.734856 1.74049 0.896223 1.88578L10.5625 10.5936C10.6475 10.6702 10.7497 10.7311 10.863 10.7728C10.9763 10.8145 11.0982 10.8359 11.2215 10.8359C11.3447 10.8359 11.4667 10.8145 11.5799 10.7728C11.6932 10.7311 11.7954 10.6702 11.8804 10.5936Z'/></svg>

        </div>
    )
}