// libraries
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import 'dayjs/locale/nb'

// components
import Section from '/react4xp/components/Section'
import Input from '/react4xp/components/Form/Input'
import Button from '/react4xp/components/Button'
import RecipeCard from '/react4xp/components/RecipeCard'
import Link from '/react4xp/components/Link'

// svg
import { GeneralSearch } from '/react4xp/components/Svg/Icons/General'
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'
import Image from '/react4xp/components/Image'

interface RecipesOverviewProps {
	title?: string
	image?: {
		src: string
		srcMedium: string
		alt: string
	}
	endpoint: string
	types: {
		id: string
		name: string
		title: string
	}[]
	startValues: {
		page?: string
		pageSize?: number
		search?: string
		types?: string[]
	}
	strings: {
		types: string
		search: string
		searchSubmit: string
		allRecipes: string
		noRecipesFound: string
	}
}

export default function RecipesOverview({title, image, endpoint, types, startValues, strings}: RecipesOverviewProps) {
	const [recipes, setRecipes] = useState<any[]>([])
    const [page, setPage] = useState(startValues?.page || 1)
    const [inputValue, setInputValue] = useState(startValues?.search || '')
	const [searchTerm, setSearchTerm] = useState(startValues?.search || '')
    const [selectedTypes, setSelectedTypes] = useState<any[]>(startValues?.types && types.filter((type)=> startValues.types.includes(type.name)) || [])
    const [hasNextPage, setHasNextPage] = useState(false)
    const [loadMore, setLoadMore] = useState(false)

    useEffect(() => {
        handleFetchRecipes()
	}, [searchTerm, selectedTypes, page])

    const handleFetchRecipes = async ()=> {
        
        if(!endpoint) return;
        
        const origin = window.location.origin;
        const url = new URL(`${origin}${endpoint}`);
        const queryParams = new URLSearchParams();

        if (page) queryParams.append('page', String(page));
        if (startValues?.pageSize) queryParams.append('pageSize', String(startValues?.pageSize));
        if (searchTerm) queryParams.append('search', searchTerm);
        if (selectedTypes.length) {
            selectedTypes.forEach(type => {
                queryParams.append('types', type.name);
            });
        }
    
        url.search = queryParams.toString();

        updateCurrentUrl()
        
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.json();
            
            if(loadMore){
                setRecipes([...recipes, ...data.hits]);
                setLoadMore(false)
            }else{
                setRecipes(data.hits);
            }

            setHasNextPage(data.hasNextPage);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    const handleSelectTypeFilter = (type) => {
        setPage(1);
        setSelectedTypes(prev => {
            const alreadySelected = prev.some(item => item.id === type.id);
            if (alreadySelected) {
                return prev.filter(item => item.id !== type.id);
            } else {
                return [...prev, { id: type.id, name: type.name }];
            }
        });
    };

	const handleSearchTerm = () => {
        setPage(1)
        setSearchTerm(inputValue)

        setTimeout(() => {
			const searchResultsElement = document.getElementById('search-results')
			if (searchResultsElement) {
				const headerOffset = 0
				const elementPosition = searchResultsElement.getBoundingClientRect().top + window.scrollY
				const offsetPosition = elementPosition - headerOffset
	
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth'
				})
			}
		}, 100)
	}
    
	const handleLoadMoreRecipes = () => {
        if(hasNextPage){
            setLoadMore(true)
            setPage(Number(page) + 1)
        }
    }

    const updateCurrentUrl = () => {
        const origin = window.location.origin;
        const updatedUrl = new URL(`${origin}${window.location.pathname}`);
        const queryParams = new URLSearchParams();

        if (searchTerm) queryParams.append('search', searchTerm);
        if (selectedTypes.length) {
            selectedTypes.forEach(type => {
                queryParams.append('types', type.name);
            });
        }

        updatedUrl.search = queryParams.toString();
        window.history.pushState({}, '', updatedUrl);
    
    }
	
	return (
        <div id='main' className='page-recipes-overview'>

        <Section
            bgColor='white-dark'
            paddingTop='small'
            paddingBottom='none'
            className='page-recipes-overview__top-part'
        >
            
            <div className='container'>
                {title && (
                    <h1 className='h1'>
                        {title}
                    </h1>
                )}
            </div>

            {image && (
                <div className='big-image'>
                    <Image
                        src={image.src}
                        srcMedium={image.srcMedium}
                        alt={image.alt}
                        className='cover'
                    />
                </div>
            )}

        </Section>

        <Section bgColor='white-dark' className='page-recipes-overview__filters'>
            <div className='container'>
                <div className='top-search'>

                    <p>
                        {strings.types}
                    </p>

                    <div className='filters'>
                        {types.map((type, i) => (
                            <Button
                                key={i}
                                hasBorders
                                hover='white-dark'
                                noUnderline
                                smallBorders
                                onClick={() => handleSelectTypeFilter(type)}
                                className={clsx(
                                    selectedTypes.some(selectedType => selectedType.id === type.id) ? 'active' : ''
                                )}
                            >
                                {type.title}
                            </Button>
                        ))}
                    </div>

                    <div className='search'>
                        
                        <Input
                            name={strings.search}
                            id='search'
                            size='large'
                            label={strings.search}
                            type='text'
                            value={searchTerm}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={() => handleSearchTerm()}
                        />

                        <Button
                            color='black'
                            hover='blue'
                            noUnderline
                            smallBorders
                            onClick={() => handleSearchTerm()}
                        >
                            {strings.searchSubmit}
                            <GeneralSearch variant='outline' />
                        </Button>

                    </div>

                </div>
            </div>
        </Section>

        <Section
            bgColor='white'
            className='page-recipes-overview__results'
            paddingBottom='small'
            id='search-results'
        >
            <div className='container'>

                <h2 className='h2'>
                    {strings.allRecipes}
                </h2>

                <div className='recipes-grid'>

                    {recipes.length > 0 ? (
                        <div className='recipes-grid__wrapper'>
                            { recipes.map((recipe) => (
                                <RecipeCard
                                    key={recipe.id}
                                    bgColor='white'
                                    href={recipe.url?.href}
                                    src={recipe.image?.link}
                                    srcMedium={recipe.image?.link}
                                    alt={recipe.image?.alt}
                                    width={400}
                                    sizes='( max-width: 992px ) 100vw, 400px'
                                    title={recipe.title}
                                    time={recipe.preparation}
                                    rating={recipe.rating}
                                    desc={recipe.intro}
                                    redOutline={true}
                                />
                            ))}
                        </div>
                    ) : (searchTerm || selectedTypes.length > 0) && (
                        <div className='recipes-grid__no-recipes'>
                            <p>
                                <b>
                                    {strings.noRecipesFound}
                                </b>
                            </p>
                        </div>
                    )}

                    {hasNextPage && (
                        <div className='recipes-grid__load-more'>
                            <Link
                                text='Last inn flere oppskrifter'
                                onClick={handleLoadMoreRecipes}
                                iconPosition='right'
                                icon={<UxArrowRight />}
                            />
                        </div>
                    )}

                </div>
            </div>
        </Section>

    </div>
	)
}