// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Button from '/react4xp/components/Button'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
interface MacroButtonProps {
    text: string
    href: string
    external?: boolean
    className?: string
}

export default function MacroButton({
    text,
    href,
    external,
    className
}: MacroButtonProps) {
    return (
        isNonWhitespaceString(text) && (
            <Button
                className={clsx('macros--button', className)}
                href={href}
                target={external}
                hasBorders
                hover='blue'
                noUnderline
            >
                {text}
            </Button>
        )
    )
}