// libraries
import React, { useState, ChangeEvent } from 'react'
import clsx from 'clsx'
import Papa from 'papaparse'

// components
import { limitCharacters } from '/lib/reactutils/utils.js'

// svg
import { GeneralTrash, GeneralSpinner } from '/react4xp/components/Svg/Icons/General'

interface CSVRow {
  	[key: string]: string | number;
}

export default function CSVUploader({serviceUrl}) {
	const [csvData, setCsvData] = useState<CSVRow[] | null>(null)
	const [fileName, setFileName] = useState<string>('')
	const [isSending, setIsSending] = useState<boolean>(false)

	// Handle CSV processing
	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setFileName(file.name);
			Papa.parse<CSVRow>(file, {
				header: true,
				complete: (results) => {
					setCsvData(results.data);
				},
				error: (error) => {
					console.error('Error parsing CSV: ', error);
				}
			});
		}
	};

	// remove file
	const handleFileRemove = () => {
        setFileName(null)

        const fileInput = document.getElementById('upload-file') as HTMLInputElement

        if (fileInput) {
            fileInput.value = ''
        }
    }

	// Handle button click to send JSON data
	const handleSendData = () => {
		if (!csvData) {
			alert("Please upload a CSV file first.");
			return;
		}

		setIsSending(true)

		fetch(serviceUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(csvData)
		})
		.then((response) => {
			if (response.ok) {
				alert('Data successfully sent!');
				setIsSending(false)
			} else {
				alert('Failed to send data.');
				setIsSending(false)
			}
		})
		.catch((error) => {
			console.error('Error sending data: ', error);
		});
	};

	return (
		<main className='page-maps'>
			<div className='container'>
				<div className='grid'>
					<div className={clsx('inner-wrapper', isSending && 'is-sending')}>
				
						<h1 className='h2'>
							Store Locator
						</h1>

						<div className='upload'>

							<input
								type='file'
								id='upload-file'
								accept='.csv'
								onChange={handleFileChange}
							/>

							<label htmlFor='upload-file' className='dash-button'>
								Select CSV
							</label>

							{fileName ? (
								<div className='filename'>

									<p>
										{limitCharacters(fileName, 25)}
									</p>

									<button onClick={handleFileRemove}>
										<GeneralTrash variant='outline' />
									</button>

								</div>
							) : (
								<p className='select'>
									Please select a CSV file
								</p>
							)}

						</div>
						
						<button
							onClick={() => handleSendData()}
							className='dash-button submit'
						>
							{isSending ? (
								<GeneralSpinner variant='outline' />
							) : (
								<span>
									Submit
								</span>
							)}

						</button>

					</div>
				</div>
			</div>
		</main>
	)
}