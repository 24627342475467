// libraries
import React from 'react'
import clsx from 'clsx'
import Masonry from 'react-masonry-css'
import Masonic from './Masonic'

// utils
import { useDomLoaded } from '/lib/reactutils/utils'

// prop types
interface MasonryGridProps {
    items: any[]
    columnGutter: number
    maxColumnCount: number
    columnWidth: number
    render: (item: any, index: number) => React.ReactNode
    breakpointCols: Record<string, number>
    className?: string
    overscanBy?: number
}

export default function MasonryGrid({
    items,
    columnGutter,
    maxColumnCount,
    columnWidth,
    render,
    breakpointCols,
    className,
    overscanBy
}: MasonryGridProps) {
    const domLoaded = useDomLoaded()

    return (
        domLoaded ? (
            <Masonic
                items={items}
                columnGutter={columnGutter}
                maxColumnCount={maxColumnCount}
                columnWidth={columnWidth}
                render={render}
                overscanBy={99}
                className={className}
            />
        ) : (
            <Masonry
                breakpointCols={breakpointCols}
                className={clsx('masonry-grid', className)}
                columnClassName='masonry-grid__column'
            >
                {items.map((item, index) => (
                    render({ data: item }, index)
                ))}
            </Masonry>
        )
    )
}
