// libraries
import React from 'react'
import clsx from 'clsx'

// svg
import { UxAngleRight } from '/react4xp/components/Svg/Ux/Angle'

// prop types
interface AnimatedArrowProps {
    className?: string
    isWhite?: boolean
}

export default function AnimatedArrow({ isWhite, className }: AnimatedArrowProps) {
    return (
        <span className={clsx(
            'animated-arrow',
            isWhite && 'white',
            className
        )}>
            <UxAngleRight />
        </span>
    )
}