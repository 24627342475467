//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Link from '/react4xp/components/Link'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'
import Image from '../Image'

// prop types
export interface SocialSectionProps {
    section: {
        bgColor: 'white' | 'yellow-light'
        paddingTop?: 'smaller' | 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'smaller' | 'small' | 'medium' | 'big' | 'none'
    }
    title?: string
    social?: Array<{
        src: string
        alt?: string
        title: string
        links: Array<{
            name: string
            href: string
            external?: boolean
        }>
    }>
}

export default function SocialSection({
    section,
    title,
    social
}: SocialSectionProps) {
    return (
        <Section
            bgColor={section?.bgColor}
            paddingTop={section?.paddingTop}
            paddingBottom={section?.paddingBottom}
            className={clsx('social-section', section?.bgColor === 'yellow-light' && 'has-background')}
        >
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 offset-lg-1'>

                        {isNonWhitespaceString(title) && (
                            <h2 className='h2 title-top'>
                                {title}
                            </h2>  
                        )}

                        <div className='grid'>
                            {social.map((item, i) => (
                                <div key={i} className='item'>
                                    {
                                        item.src && 
                                        <Image
                                            src={item.src}
                                            alt={item.alt}
                                            width={250}
                                            className='logo'
                                        />
                                    }

                                    {isNonWhitespaceString(item.title) && (
                                        <h3 className='h4'>
                                            {item.title}
                                        </h3>
                                    )}

                                    <div className='links'>
                                        {item.links.map((link, i2) => (
                                            <Link
                                                key={i2}
                                                href={link.href}
                                                target={link.external}
                                                text={link.name}
                                            />
                                        ))}
                                    </div>
                                    
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        </Section>
    )
}