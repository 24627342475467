// libraries
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import 'dayjs/locale/nb'

// components
import Section from '/react4xp/components/Section'
import Input from '/react4xp/components/Form/Input'
import Button from '/react4xp/components/Button'
import Link from '/react4xp/components/Link'
import ProductCard from '/react4xp/components/ProductCard'
import ProductCardBarcode from '/react4xp/components/ProductCardBarcode'

// svg
import { GeneralSearch } from '/react4xp/components/Svg/Icons/General'
import { UxArrowDown } from '/react4xp/components/Svg/Ux/Arrow'

interface ProductsOverviewProps {
	title?: string
	endpoint?: string
	categories: {
		id: string
		title: string
		name: string
	}[]
	additionalPackaging?: {
		heading: string
		options: {
			image: {
				link: string
                alt?: string
			}
			title: string
			quantity: string
			barcode: string
		}[]
	}
	startValues: {
		page?: string
		pageSize?: number
		search?: string
		categories?: string[]
	}
	strings: {
        searchLabel: string
		selectCategory: string
		search: string
		products: string
		noProductsFound: string
	}
}

export default function ProductsOverview({title, endpoint, categories, additionalPackaging, startValues, strings} : ProductsOverviewProps) {
	const [products, setProducts] = useState<any[]>([])
    const [page, setPage] = useState(startValues?.page || 1)
    const [inputValue, setInputValue] = useState(startValues?.search || '')
	const [searchTerm, setSearchTerm] = useState(startValues?.search || '')
    const [selectedCategories, setSelectedCategories] = useState<any[]>(startValues?.categories && categories.filter((category)=> startValues.categories.includes(category.name)) || [])
    const [hasNextPage, setHasNextPage] = useState(false)
    const [loadMore, setLoadMore] = useState(false)

    useEffect(() => {
        handleFetchArticles()
	}, [searchTerm, selectedCategories, page])

    const handleFetchArticles = async ()=> {
        
        if(!endpoint) return;
        
        const origin = window.location.origin;
        const url = new URL(`${origin}${endpoint}`);
        const queryParams = new URLSearchParams();

        if (page) queryParams.append('page', String(page));
        if (startValues?.pageSize) queryParams.append('pageSize', String(startValues?.pageSize));
        if (searchTerm) queryParams.append('search', searchTerm);
        if (selectedCategories.length) {
            selectedCategories.forEach(category => {
                queryParams.append('categories', category.name);
            });
        }
    
        url.search = queryParams.toString();

        updateCurrentUrl()

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.json();
            
            if(loadMore){
                setProducts([...products, ...data.hits]);
                setLoadMore(false)
            }else{
                setProducts(data.hits);
            }

            setHasNextPage(data.hasNextPage);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    const handleSelectCategoryFilter = (category) => {
        setPage(1);
        setSelectedCategories(prev => {
            const tagExists = prev.some(item => item.id === category.id);
            if (tagExists) {
                return prev.filter(item => item.id !== category.id);
            } else {
                return [...prev, { id: category.id, title: category.title, name: category.name }];
            }
        });
    };

	const handleSearchTerm = () => {
        setPage(1)
        setSearchTerm(inputValue)

        setTimeout(() => {
			const searchResultsElement = document.getElementById('search-results')
			if (searchResultsElement) {
				const headerOffset = 0
				const elementPosition = searchResultsElement.getBoundingClientRect().top + window.scrollY
				const offsetPosition = elementPosition - headerOffset
	
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth'
				})
			}
		}, 100)
	}
    
	const handleLoadMoreProducts = () => {
        if(hasNextPage){
            setLoadMore(true)
            setPage(Number(page) + 1)
        }
    }

    const updateCurrentUrl = () => {
        const origin = window.location.origin;
        const updatedUrl = new URL(`${origin}${window.location.pathname}`);
        const queryParams = new URLSearchParams();

        if (searchTerm) queryParams.append('search', searchTerm);
        if (selectedCategories.length) {
            selectedCategories.forEach(category => {
                queryParams.append('categories', category.name);
            });
        }

        updatedUrl.search = queryParams.toString();
        window.history.pushState({}, '', updatedUrl);
    
    }
	
	return (
        <div id='main' className='page-products-overview'>

            <Section paddingTop='small'>
                <div className='container'>

                    <div className='top-search'>

                        {title && (
                            <h1 className='h1'>
                                {title}
                            </h1>
                        )}

                        <p className='text-small'>
                            {strings.selectCategory}
                        </p>

                        <div className='top-search__filters'>

                            {categories.map((category, i) => (
                                <Button
                                    hasBorders
                                    hover='white-dark'
                                    noUnderline
                                    key={i}
                                    smallBorders
                                    onClick={() => handleSelectCategoryFilter(category)}
                                    className={clsx(
                                        selectedCategories.some(selectedTag => selectedTag.id === category.id) ? 'active' : ''
                                    )}
                                >
                                    {category.title}
                                </Button>
                            ))}

                        </div>

                        <div className='top-search__search'>
                            
                            <Input
                                name={strings.searchLabel}
                                id='search'
                                size='large'
                                label={strings.searchLabel}
                                type='text'
                                value = {inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyDown={() => handleSearchTerm()}
                            />

                            <Button
                                color='black'
                                hover='blue'
                                noUnderline
                                smallBorders
                                onClick={() => handleSearchTerm()}
                            >
                                {strings.search}
                                <GeneralSearch variant='outline' />
                            </Button>

                        </div>
                        
                    </div>
                </div>
            </Section>
            <Section bgColor='white-dark' id='search-results'>
                <div className='container'>

                    <h2 className='h3'>
                        {strings.products}
                    </h2>

                    <div className='products-grid'>

                        {products.length > 0 ? (
                            <div className='products-grid__wrapper'>
                            
                                { products.map((product, i) => (
                                    <ProductCard
                                        data-category={product.category}
                                        key={product.id}
                                        bgColor='white'
                                        href={product.url?.href}
                                        src={product.image?.link}
                                        srcMedium={product.image?.link}
                                        alt={product.image?.alt}
                                        width={400}
                                        sizes='( max-width: 992px ) 100vw, 400px'
                                        title={product.title}
                                        comments={product.comments}
                                        rating={product.rating}
                                    />
                                ))}
                                
                            </div>
                        ) :  (searchTerm || selectedCategories.length > 0) && (
                            <div className='products-grid__no-products'>
                                <p>
                                    <b>
                                        {strings.noProductsFound}
                                    </b>
                                </p>
                            </div>
                        )}

                        {hasNextPage && (
                            <div className='products-grid__load-more'>
                                <Link
                                    text='Last inn flere produkter'
                                    onClick={handleLoadMoreProducts}
                                    iconPosition='right'
                                    icon={<UxArrowDown />}
                                />
                            </div>
                        )}

                    </div>

                </div>
            </Section>

            {additionalPackaging?.options?.length > 0 && (
                <Section className='more-products' paddingTop='small'>
                    <div className='container'>

                        {additionalPackaging.heading && (
                            <h2 className='more-products__title h3'>
                                {additionalPackaging.heading}
                            </h2>
                        )}

                        <div className='more-products__grid'>
                            {additionalPackaging?.options?.map((option, i) => (
                                <ProductCardBarcode
                                    key={i}
                                    bgColor='white-dark'
                                    src={option.image?.link}
                                    srcMedium={option.image?.link}
                                    alt={option.image?.alt}
                                    width={400}
                                    sizes='( max-width: 992px ) 100vw, 400px'
                                    title={option.title}
                                    quantity={option.quantity}
                                    barcode={option.barcode}
                                />
                            ))}
                        </div>
                    </div>
                </Section>
            )}
        </div>
	)
}