// libraries
import React from 'react'
import { Masonry } from 'masonic'

// prop types
export interface MasonicProps {
    items: any[]
    columnGutter: number
    maxColumnCount: number
    columnWidth: number
    render: (item: any, index: number) => React.ReactNode
    className?: string
    overscanBy?: number
}

export default function Masonic({
    items,
    columnGutter,
    maxColumnCount,
    columnWidth,
    render,
    className,
    overscanBy
}: MasonicProps) {
    return (
        <Masonry
            items={items}
            columnGutter={columnGutter}
            maxColumnCount={maxColumnCount}
            columnWidth={columnWidth}
            overscanBy={overscanBy}
            render={render}
            className={className}
        />
    )
}