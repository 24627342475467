// libraries
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Input from '/react4xp/components/Form/Input'
import Button from '/react4xp/components/Button'
import ArticleCard from '/react4xp/components/ArticleCard'
import Link from '/react4xp/components/Link'
import Image from '/react4xp/components/Image'

// svg
import { GeneralSearch } from '/react4xp/components/Svg/Icons/General'
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

interface ArticlesOverviewProps {
    title?: string
    image?: {
        src: string
        srcMedium: string
        alt: string
    }
    tags?: {
        id: string
        title: string
    }[]
    endpoint: string
    startValues: {
        page?: string
        pageSize?: number
        search?: string
        tags?: string[]
    }
    strings: {
        selectTags: string
        search: string
        searchSubmit: string
        allArticles: string
        noArticlesFound: string
    }
}

export default function ArticlesOverview({title, image, tags, endpoint, startValues, strings} : ArticlesOverviewProps) {
	const [articles, setArticles] = useState<any[]>([])
    const [page, setPage] = useState(startValues?.page || 1)
    const [inputValue, setInputValue] = useState(startValues?.search || '')
	const [searchTerm, setSearchTerm] = useState(startValues?.search || '')
    const [selectedTags, setSelectedTags] = useState<any[]>(startValues?.tags && tags.filter((tag)=> startValues.tags.includes(tag.title)) || [])
    const [hasNextPage, setHasNextPage] = useState(false)
    const [loadMore, setLoadMore] = useState(false)

    useEffect(() => {
        handleFetchArticles()
	}, [searchTerm, selectedTags, page])

    const handleFetchArticles = async ()=> {
        
        if(!endpoint) return;
        
        const origin = window.location.origin;
        const url = new URL(`${origin}${endpoint}`);
        const queryParams = new URLSearchParams();

        if (page) queryParams.append('page', String(page));
        if (startValues?.pageSize) queryParams.append('pageSize', String(startValues?.pageSize));
        if (searchTerm) queryParams.append('search', searchTerm);
        if (selectedTags.length) {
            selectedTags.forEach(tag => {
                queryParams.append('tags', tag.title);
            });
        }
    
        url.search = queryParams.toString();

        updateCurrentUrl()
        
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.json();
            
            if(loadMore){
                setArticles([...articles, ...data.hits]);
                setLoadMore(false)
            }else{
                setArticles(data.hits);
            }

            setHasNextPage(data.hasNextPage);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    const handleSelectTagFilter = (tag) => {
        setPage(1);
        setSelectedTags(prev => {
            const tagExists = prev.some(item => item.id === tag.id);
            if (tagExists) {
                return prev.filter(item => item.id !== tag.id);
            } else {
                return [...prev, { id: tag.id, title: tag.title }];
            }
        });
    };

	const handleSearchTerm = () => {
        setPage(1)
        setSearchTerm(inputValue)

        setTimeout(() => {
			const searchResultsElement = document.getElementById('search-results')
			if (searchResultsElement) {
				const headerOffset = 0
				const elementPosition = searchResultsElement.getBoundingClientRect().top + window.scrollY
				const offsetPosition = elementPosition - headerOffset
	
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth'
				})
			}
		}, 100)
	}
    
	const handleLoadMoreArticles = () => {
        if(hasNextPage){
            setLoadMore(true)
            setPage(Number(page) + 1)
        }
    }

    const updateCurrentUrl = () => {
        const origin = window.location.origin;
        const updatedUrl = new URL(`${origin}${window.location.pathname}`);
        const queryParams = new URLSearchParams();

        if (searchTerm) queryParams.append('search', searchTerm);
        if (selectedTags.length) {
            selectedTags.forEach(tag => {
                queryParams.append('tags', tag.title);
            });
        }

        updatedUrl.search = queryParams.toString();
        window.history.pushState({}, '', updatedUrl);
    
    }

	return (
		<div id='main' className='page-articles-overview'>

			<Section
				bgColor='white-dark'
				paddingTop='smaller'
				className='page-articles-overview__header'
			>
				<div className='container'>

					<div className='banner'>
						<div className='row'>

							{title && (
								<div className='col-md-6 col-lg-5 left'>
										<h1 className='h1'>
											{title}
										</h1>		
								</div>
							)}

							{image && (
								<div className='col-md-6 col-lg-7 right'>
									<div className='image'>
										<Image
											src={image.src}
											srcMedium={image.srcMedium}
											alt={image.alt}
											className='cover'
										/>
									</div>
								</div>
							)}

						</div>
					</div>

					<div className='top-search'>

						<p className='text-small'>
							{strings.selectTags}
						</p>

						<div className='top-search__filters'>
							{tags?.map((tag) => (
								<Button
									hasBorders
									hover='white-dark'
									noUnderline
									key={tag.id}
									smallBorders
									onClick={() => handleSelectTagFilter(tag)}
									className={clsx(
										selectedTags.some(selectedTag => selectedTag.id === tag.id) ? 'active' : ''
									)}
								>
									{tag.title}
								</Button>
							))}
						</div>

						<div className='top-search__search'>
							
							<Input
								id='search'
								size='full-width'
								name={strings.search}
								label={strings.search}
								type='text'
								required
								errorMessages={{
									required: 'Dette feltet er obligatorisk'
								}}
								value = {inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onKeyDown={() => handleSearchTerm()}
							/>

							<Button
								color='black'
								hover='red'
								noUnderline
								smallBorders
								onClick={() => handleSearchTerm()}
							>
								{strings.searchSubmit}
								<GeneralSearch variant='outline' />
							</Button>

						</div>
						
					</div>

				</div>
			</Section>

			<Section
				bgColor='white'
				className='page-articles-overview__body'
				paddingBottom='small'
				id='search-results'
			>
				<div className='container'>

					<h2 className='h2'>
						{strings.allArticles}
					</h2>

					<div className='articles-grid'>

						{articles.length > 0 ? (
							<div className='articles-grid__wrapper'>
							
								{ articles.map((article, i) => (
									<ArticleCard
										key={article.id}
										href={article.url?.href}
										src={article.image?.link}
										alt={article.image?.alt}
										width={400}
										sizes='( max-width: 992px ) 100vw, 400px'
										title={article.title}
										desc={article.intro}
									/>
								))}
								
							</div>
						) : (searchTerm || selectedTags.length > 0) && (
							<div className='articles-grid__no-articles'>
								<p className='h4'>
									{strings.noArticlesFound}
								</p>
							</div>
						)}

						{hasNextPage && (
							<div className='articles-grid__load-more'>
								<Link
									text='Se flere nyhetssaker'
									onClick={handleLoadMoreArticles}
									iconPosition='right'
									icon={<UxArrowRight />}
								/>
							</div>
						)}

					</div>
				</div>
			</Section>

		</div>
	)
}