// libraries
import React from 'react'

// components
import Section from '/react4xp/components/Section'
import Button from '/react4xp/components/Button'
import Link from '/react4xp/components/Link'
import Form from '/react4xp/components/Form/Form'
import Checkbox from '/react4xp/components/Form/Checkbox'

// prop types
interface CreateUserProps {
    strings: {
        registerHeader: string

        registerErrorPopupClose: string
        registerErrorPopupHeader: string
        registerErrorPopupContent: string

        registerConsentNewsletter: string
        registerConsentWin: string
        registerConsentOver13: string
        registerConsentTCBefore: string
        registerConsentTCAfter: string

        validationRequired: string

        registerCreateUser: string
        registerCancel: string
    }
    registerServiceUrl: string
    profilePageUrl: string
    termsAndConditionsUrl: string
    logoutUrl: string
}

export default function CreateUser({
    strings,
    registerServiceUrl,
    profilePageUrl,
    termsAndConditionsUrl,
    logoutUrl
}: CreateUserProps) {
    return (
        <Section bgColor='red' paddingTop='small'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'>
                        <div className='page-user__content bg-white'>

                            <h1 className='page-user__title h2'>
                                {strings.registerHeader}
                            </h1>

                            <Form
                                endpoint={registerServiceUrl}
                                className='page-user__form'
                                onSuccess={() => {
                                    window.location.href=profilePageUrl
                                }}
                                popupErrorContent={{
                                    hasCloseButton: true,
                                    closeButtonText: strings.registerErrorPopupClose,
                                    title: strings.registerErrorPopupHeader,
                                    content: strings.registerErrorPopupContent,
                                    actionButtons: {
                                        primary: {
                                            text: strings.registerErrorPopupClose
                                        }
                                    }
                                }}
                            >

                                <Checkbox
                                    id='create-user-newsletter'
                                    name='newsletter'
                                >
                                    {strings.registerConsentNewsletter}
                                </Checkbox>

                                <Checkbox
                                    id='create-user-contact-win'
                                    name='contact-win'
                                    required
                                    errorMessages={{
                                        required: strings.validationRequired
                                    }}
                                >
                                    {strings.registerConsentWin}
                                </Checkbox>

                                <Checkbox
                                    id='create-user-over-13'
                                    name='over-13'
                                    required
                                    errorMessages={{
                                        required: strings.validationRequired
                                    }}
                                >
                                    {strings.registerConsentOver13}
                                </Checkbox>

                                <Checkbox
                                    id='create-user-terms'
                                    name='terms-and-conditions'
                                    className='page-user__margin-bottom'
                                    required
                                    errorMessages={{
                                        required: strings.validationRequired
                                    }}
                                >
                                    {strings.registerConsentTCBefore} <a href={termsAndConditionsUrl}>{strings.registerConsentTCAfter}</a>
                                </Checkbox>

                                <Button
                                    color='black'
                                    hover='red'
                                    type='submit'
                                    noUnderline
                                >
                                    {strings.registerCreateUser}
                                </Button>

                                <Link
                                    text={strings.registerCancel}
                                    href={logoutUrl}
                                    className='page-user__margin-top'
                                />

                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}
