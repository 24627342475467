//libraries
import React, { useState } from 'react'
import clsx from 'clsx'

// components
import { UxAngleDown } from '/react4xp/components/Svg/Ux/Angle'
import RichText from '../RichText'

// prop types
export interface AccordionProps {
    question: string
    answer: string
    isRed?: boolean
}

export default function Accordion({
    question,
    answer,
    isRed
}: AccordionProps) {

    const [isActive, setIsActive] = useState(false)

	const toggle = () => {
		setIsActive((prev) => !prev)
	}

    return (
        <div className={clsx(
            'accordion-component',
            isActive && 'accordion-component--is-active',
            isRed && 'accordion-component--is-red'
        )}>

            <button
                className='accordion-component__title'
                onClick={toggle}
            >
                <UxAngleDown />
                {question}
            </button>

            <div className='accordion-component__content text-small'>
                <div>
                    <RichText content={answer} />
                </div>
            </div>

        </div>
    )
}