//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'
import Video from '/react4xp/components/Video'
import RichText from '/react4xp/components/RichText'
import LinkList, { LinkListProps } from '/react4xp/components/LinkList'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface HeroBannerProps {
    title?: string
    type?: 'image' | 'video'
    image?: {
        src?: string
        srcMedium?: string
        srcSmall?: string
        alt?: string
    }
    video?: {
        url: string
        playText: string
        videoType: 'youtube' | 'vimeo' | 'default'
        poster?: string
        posterMedium?: string
    }
    content?: string
    listLinks?: LinkListProps[]
}

export default function HeroBanner({
    title,
    type,
    image,
    video,
    content,
    listLinks
}: HeroBannerProps) {
    return (
        <Section
            bgColor='white'
            paddingTop='none'
            paddingBottom='none'
            className='hero-banner'
        >

            <div className='hero-banner__title'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                            {isNonWhitespaceString(title) && (
                                <h1 className='h1-hero'>
                                    {title}
                                </h1>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {(type === 'image') && image && image.src && (
                <div className='hero-banner__image'>
                    <Image
                        src={image.src} // 1600x720px
                        srcMedium={image.srcMedium} // 1100x500
                        srcSmall={image.srcSmall} // 600x400
                        alt={image.alt}
                        width={1600} 
                        className='cover'
                    />
                </div>
            )}

            {(type === 'video') && video && video.url && (
                <div className='hero-banner__video'>
                    <div className='container'>
                        <Video
                            video={{
                                url: video.url,
                                playButtonColor: 'red',
                                playText: video.playText,
                                playTextColor: 'white',
                                videoType: video.videoType,
                                poster: video.poster, // 1400x750
                                posterMedium: video.posterMedium // 600x400
                            }}
                        />
                    </div>
                </div>
            )}

            <div className='hero-banner__content'>
                
                {type === 'image' && (
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                                <div className={clsx(
                                    'red-box',
                                    !image && 'red-box--no-image'
                                )}>
                                    
                                    <RichText
                                        className='text-big'
                                        content={content}
                                    />

                                    {listLinks && (
                                        <div className='list'>
                                            {listLinks.map((item: LinkListProps, i) => (
                                                <LinkList
                                                    key={i}
                                                    style='inline'
                                                    {...item}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {type === 'video' && (
                    <div className='hero-banner__content--video'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                                    
                                    <RichText
                                        className='text-big'
                                        content={content}
                                    />

                                    {listLinks && (
                                        <div className='list'>
                                            {listLinks.map((item: LinkListProps, i) => (
                                                <LinkList
                                                    key={i}
                                                    style='inline'
                                                    {...item}
                                                />
                                            ))}
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </Section>
    )
}