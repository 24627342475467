// libraries
import React from 'react'

// svg
import { GeneralStar, GeneralStarFull } from '/react4xp/components/Svg/Icons/General'

// prop types
export interface StarsProps {
    howMany: number
    isWhite?: boolean
}

export default function Stars({
    howMany = 0,
    isWhite
}: StarsProps) {
    const renderStars = () => {
        const stars = []
        const fullStars = Math.floor(howMany)
        const fraction = howMany - fullStars
        const hasHalfStar = fraction >= 0.5
        const outlinedStars = 5 - fullStars - (hasHalfStar ? 1 : 0)

        for (let i = 0; i < fullStars; i++) {
            stars.push(<GeneralStarFull key={`full-${i}`} />)
        }

        if (hasHalfStar) {
            stars.push(<GeneralStarFull key={`half-${fullStars}`} />)
        }

        for (let i = 0; i < outlinedStars; i++) {
            stars.push(<GeneralStar key={`outline-${i}`} variant='outline' />)
        }

        return stars
    };

    return (
        <div className='stars-counter'>
            {renderStars()}
        </div>
    )
}
