// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Image from '/react4xp/components/Image'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// svg
import { GeneralClock, GeneralStar } from '/react4xp/components/Svg/Icons/General'
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'

// prop types
interface RecipeCardProps {
    bgColor?: 'white' | 'white-dark'
    href?: string
    external?: boolean
    src?: string
    srcMedium?: string
    alt?: string
    width?: number
    sizes?: string
    title?: string
    time?: string
    rating?: string
    desc?: string
    whiteText?: boolean
    redOutline?: boolean
    className?: string
}

export default function RecipeCard({
    bgColor,
    href,
    external,
    src,
    srcMedium,
    alt,
    width,
    sizes,
    title,
    time,
    rating,
    desc,
    whiteText,
    redOutline,
    className,
    ...rest
}: RecipeCardProps) {
    return (
        <a
            href={href}
            target={external ? '_blank' : undefined}
            className={clsx(
                'recipe-card',
                className
            )}
            {...rest}
        >
            <div className='recipe-card__mask'>

                {src && (
                    <>
                    
                        <div className='recipe-card__image'>
                            <Image
                                src={src}
                                srcMedium={srcMedium}
                                alt={alt}
                                width={width}
                                sizes={sizes}
                                className='cover'
                            />
                        </div>

                        {redOutline && (
                            <div className='recipe-card__red-outline' />
                        )}

                    </>
                )}

                <div className={clsx('recipe-card__wrapper', whiteText && 'white')}>

                    {isNonWhitespaceString(title) && (
                        <h3 className='recipe-card__wrapper__title h4'>
                            {title}
                        </h3>  
                    )}

                    {(time || rating) && (
                        <div className='recipe-card__wrapper__icons text-smaller'>

                            {isNonWhitespaceString(time) && (
                                <span className='recipe-card__wrapper__icons__item'>
                                    <GeneralClock variant='outline' />
                                    <span>
                                        {time}
                                    </span>
                                </span>
                            )}

                            <span className='recipe-card__wrapper__icons__item'>
                                <GeneralStar variant='outline' />
                                <span>
                                    {rating ? rating : '--'}
                                </span>
                            </span>

                        </div>
                    )}

                    {isNonWhitespaceString(desc) && (
                        <div
                            className='recipe-card__wrapper__desc'
                            dangerouslySetInnerHTML={{ __html: desc }}
                            suppressHydrationWarning
                        />
                    )}

                    <div className='recipe-card__wrapper__arrow'>
                        <UxArrowRight />
                    </div>
                    
                </div>
            </div>

        </a>
    )
}