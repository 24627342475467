// libraries
import React from 'react'
import clsx from 'clsx'

// svg
import { GeneralSpinner } from '/react4xp/components/Svg/Icons/General'

// prop types
interface ButtonProps {
    color?: 'white-dark' | 'blue' | 'transparent' | 'black' | 'red'
    hover?: 'white-dark' | 'blue' | 'black' | 'red'
    href?: string
    target?: boolean
    type?: 'button' | 'submit'
    hasBorders?: boolean
    smallBorders?: boolean
    noUnderline?: boolean
    bigButton?: boolean
    className?: string
    children: React.ReactNode
    onClick?: (e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>) => void
    disabled?: boolean
}

export default function Button({
    hasBorders,
    smallBorders,
    bigButton,
    href,
    target,
    type,
    children,
    color,
    hover,
    noUnderline,
    className,
    onClick,
    ...rest
}: ButtonProps) {

    const customStyle = color && !['white-dark', 'blue', 'transparent', 'black', 'red'].includes(color) ? { backgroundColor: color } : {}

    // check if the element is a link or a button based on the presence of href
    const Element = href ? 'a' : 'button'

    const elementProps = {
        href: href || null,
        target: target ? '_blank' : undefined,
        type: Element === 'button' ? type : undefined,
        onClick: onClick || undefined,
        className: clsx(
            'button',
            smallBorders && 'button-small-borders',
            hasBorders && 'button-has-borders',

            // color
            color === 'white-dark' && 'bg-white-dark',
            color === 'blue' && 'bg-blue white',
            color === 'transparent' && 'bg-transparent',
            color === 'black' && 'bg-black white',
            color === 'red' && 'bg-red white',

            // hover color
            hover === 'white-dark' && 'hover-bg-white-dark',
            hover === 'blue' && 'hover-bg-blue',
            hover === 'black' && 'hover-bg-black',
            hover === 'red' && 'hover-bg-red',

            noUnderline && 'no-underline',

            bigButton && 'button-big',

            type === 'submit' && 'button--submit',
            className
        ),
        ...rest
    }

    return (
        <Element {...elementProps} style={customStyle}>

            {type === 'submit' ? (
                <>
                    <span className='button__spinner'>
                        <GeneralSpinner variant='outline' />
                    </span>

                    <span className='relative z0'>
                        {children}
                    </span>
                </>
            ) : (
                children
            )}
            
        </Element>
    )
}