// libraries
import React from 'react'

export function UxArrowUp() {
    return (
        <svg width='16' height='20' viewBox='0 0 16 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M14.9454 8.66085C14.5008 8.88314 13.9602 8.70294 13.7379 8.25836C12.7495 6.28159 10.9293 4.28279 8.9769 2.9618L8.97686 18.5426C8.97686 19.0396 8.57391 19.4426 8.07686 19.4426C7.5798 19.4426 7.17686 19.0396 7.17686 18.5426L7.1769 2.96172C5.22422 4.28273 3.40361 6.28159 2.41522 8.25836C2.19293 8.70294 1.65233 8.88314 1.20775 8.66085C0.763166 8.43856 0.582964 7.89796 0.805255 7.45338C2.18563 4.69263 4.91366 1.96497 7.67445 0.584741C7.92781 0.458077 8.22602 0.458081 8.47938 0.584753C11.24 1.96501 13.9676 4.69269 15.3479 7.45338C15.5702 7.89796 15.39 8.43856 14.9454 8.66085Z'/></svg>
    )
}

export function UxArrowDown() {
    return (
        <svg width='15' height='20' viewBox='0 0 15 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M0.497705 11.2713C0.942286 11.049 1.48289 11.2292 1.70518 11.6738C2.69357 13.6505 4.51382 15.6493 6.46621 16.9703L6.46626 1.38955C6.46626 0.892492 6.8692 0.48955 7.36626 0.489552C7.86331 0.489552 8.26626 0.892496 8.26626 1.38955L8.26621 16.9704C10.2189 15.6494 12.0395 13.6505 13.0279 11.6738C13.2502 11.2292 13.7908 11.049 14.2354 11.2713C14.6799 11.4936 14.8602 12.0342 14.6379 12.4788C13.2575 15.2395 10.5295 17.9672 7.76866 19.3474C7.5153 19.4741 7.21709 19.474 6.96374 19.3474C4.20308 17.9671 1.47556 15.2394 0.0952127 12.4788C-0.127078 12.0342 0.0531244 11.4936 0.497705 11.2713Z'/></svg>
    )
}

export function UxArrowLeft() {
    return (
        <svg width='23' height='15' viewBox='0 0 23 15' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M8.2712 0.597314C8.49349 1.04189 8.31329 1.5825 7.86871 1.80479C5.89194 2.79318 3.89314 4.61343 2.57215 6.56582L21.1529 6.56587C21.65 6.56587 22.0529 6.96881 22.0529 7.46587C22.0529 7.96292 21.65 8.36587 21.1529 8.36587L2.57207 8.36582C3.89308 10.3185 5.89194 12.1391 7.86871 13.1275C8.31329 13.3498 8.49349 13.8904 8.2712 14.335C8.04891 14.7796 7.50831 14.9598 7.06373 14.7375C4.30298 13.3571 1.57532 10.6291 0.195093 7.86827C0.0684284 7.61491 0.0684326 7.3167 0.195105 7.06334C1.57536 4.30269 4.30304 1.57517 7.06373 0.194822C7.50831 -0.0274682 8.04891 0.152734 8.2712 0.597314Z'/></svg>
    )
}

export function UxArrowRight() {
    return (
        <svg width='22' height='15' viewBox='0 0 22 15' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M13.7818 14.3348C13.5595 13.8902 13.7397 13.3496 14.1843 13.1273C16.161 12.139 18.1598 10.3187 19.4808 8.36631L0.900048 8.36626C0.402991 8.36626 4.82842e-05 7.96332 5.0235e-05 7.46626C5.02785e-05 6.9692 0.402995 6.56626 0.900052 6.56626L19.4809 6.5663C18.1599 4.61362 16.161 2.79301 14.1843 1.80463C13.7397 1.58234 13.5595 1.04173 13.7818 0.59715C14.0041 0.15257 14.5447 -0.0276324 14.9893 0.194659C17.75 1.57503 20.4777 4.30306 21.8579 7.06386C21.9846 7.31722 21.9845 7.61543 21.8579 7.86878C20.4776 10.6294 17.7499 13.357 14.9892 14.7373C14.5447 14.9596 14.0041 14.7794 13.7818 14.3348Z'/></svg>
    )
}