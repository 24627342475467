// libraries
import React from 'react'
import clsx from 'clsx'

// prop types
interface LinkProps {
    isWhite?: boolean
    text: string
    href?: string
    type?: 'button' | 'submit'
    target?: boolean
    noUnderline?: boolean
    noMotionMobile?: boolean
    icon?: React.ReactNode
    iconPosition?: 'left' | 'right'
    className?: string
    onClick?: () => void
}

export default function Link({
    isWhite,
    text,
    href,
    type,
    target,
    noUnderline,
    noMotionMobile,
    icon,
    iconPosition,
    className,
    onClick,
    ...rest
}: LinkProps) {

    // check if the element is a link or a button based on the presence of href
    const Element = href ? 'a' : 'button'

    const elementProps = {
        href: href || null,
        target: target ? '_blank' : undefined,
        type: Element === 'button' ? type : undefined,
        onClick: onClick || undefined,
        className: clsx(
            'link-w-icon',
            noUnderline && 'no-underline',
            noMotionMobile && 'link-w-icon--no-motion-mobile',
            iconPosition === 'left' && 'link-w-icon--icon-left',
            iconPosition === 'right' && 'link-w-icon--icon-right',
            isWhite && 'white',
            className
        ),
        ...rest
    }

    return (
        <Element {...elementProps}>
            {iconPosition === 'left' && icon}
            <span>{text}</span>
            {iconPosition === 'right' && icon}
        </Element>
    )
}