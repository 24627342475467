// libraries
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

export default function Guidelines() {

    // show / hide grid
    const [isGridVisible, setIsGridVisible] = useState(false)

    const showHideGrid = () => {
        setIsGridVisible((prev) => !prev)
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.shiftKey && event.key.toLowerCase() === 'g') {
                event.preventDefault()
                showHideGrid()
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    return (
        <>

            <button onClick={showHideGrid} className='guidelines-button'>
                Grid
            </button>

            <div className='breakpoint-indicator'></div>

            <div className={clsx('guidelines-grid', isGridVisible && 'visible')}>
                <div className='container'>
                    <div className='row'>
                        
                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                        <div className='col-3 col-sm-2 col-md-1'>
                            <div className='block'></div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}