// libraries
import React from 'react'

export function UxAngleUp() {
    return (
        <svg width='22' height='12' viewBox='0 0 22 12' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M1.50222 11.4197C1.00825 11.1727 0.808021 10.5721 1.05501 10.0781C2.96194 6.26423 6.73921 2.48695 10.553 0.580173C10.8345 0.439426 11.1658 0.439421 11.4473 0.580161C15.2614 2.48694 19.0386 6.26423 20.9456 10.0781C21.1926 10.5721 20.9923 11.1727 20.4984 11.4197C20.0044 11.6667 19.4037 11.4665 19.1567 10.9725C17.5178 7.69474 14.2779 4.38349 11.0002 2.60238C7.72273 4.3835 4.48275 7.69475 2.84387 10.9725C2.59688 11.4665 1.9962 11.6667 1.50222 11.4197Z'/></svg>
    )
}

export function UxAngleDown() {
    return (
        <svg width='22' height='12' viewBox='0 0 22 12' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M1.50222 0.580401C1.00825 0.82739 0.808021 1.42806 1.05501 1.92204C2.96194 5.73591 6.73921 9.51318 10.553 11.42C10.8345 11.5607 11.1658 11.5607 11.4473 11.42C15.2614 9.5132 19.0386 5.73591 20.9456 1.92204C21.1926 1.42806 20.9923 0.827391 20.4984 0.580402C20.0044 0.333413 19.4037 0.533637 19.1567 1.02762C17.5178 4.3054 14.2779 7.61665 11.0002 9.39776C7.72273 7.61664 4.48275 4.30539 2.84387 1.02761C2.59688 0.533636 1.9962 0.333412 1.50222 0.580401Z'/></svg>
    )
}

export function UxAngleLeft() {
    return (
        <svg width='12' height='22' viewBox='0 0 12 22' xmlns='http://www.w3.org/2000/svg'><path d='M11.4176 20.4978C11.1707 20.9918 10.57 21.192 10.076 20.945C6.26214 19.0381 2.48486 15.2608 0.578082 11.447C0.437335 11.1655 0.43733 10.8342 0.57807 10.5527C2.48485 6.73863 6.26214 2.96135 10.076 1.05442C10.57 0.80743 11.1707 1.00765 11.4176 1.50163C11.6646 1.99561 11.4644 2.59628 10.9704 2.84328C7.69265 4.48216 4.3814 7.72215 2.60029 10.9998C4.38141 14.2773 7.69266 17.5172 10.9704 19.1561C11.4644 19.4031 11.6646 20.0038 11.4176 20.4978Z'/></svg>
    )
}

export function UxAngleRight() {
    return (
        <svg width='12' height='22' viewBox='0 0 12 22' xmlns='http://www.w3.org/2000/svg'><path d='M0.105792 1.50222C0.352781 1.00825 0.953454 0.808021 1.44743 1.05501C5.2613 2.96194 9.03857 6.73921 10.9454 10.553C11.0861 10.8345 11.0861 11.1658 10.9454 11.4473C9.03859 15.2614 5.2613 19.0386 1.44743 20.9456C0.953454 21.1926 0.352781 20.9923 0.105792 20.4984C-0.141197 20.0044 0.059027 19.4037 0.553006 19.1567C3.83079 17.5178 7.14204 14.2779 8.92315 11.0002C7.14203 7.72273 3.83078 4.48275 0.553006 2.84387C0.059027 2.59688 -0.141197 1.9962 0.105792 1.50222Z'/></svg>
    )
}