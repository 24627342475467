//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import RichText from '/react4xp/components/RichText'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface TwoColumnsCardProps {
    section?: {
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
    }
    blockColor: 'red' | 'yellow'
    title?: string
    text?: string
    content?: string
}

export default function TwoColumnsCard({
    section,
    blockColor,
    title,
    text,
    content
}: TwoColumnsCardProps) {
    return (
        <Section
            bgColor='white'
            paddingTop={section?.paddingTop}
            paddingBottom={section?.paddingBottom}
            className='two-columns-card'
        >
            <div className='container'>
                <div className={clsx(
                    'two-columns-card__box',
                    blockColor === 'red' && 'bg-red-light',
                    blockColor === 'yellow' && 'bg-yellow-light'
                )}>
                    <div className='row'>

                        <div className='col-md-6 left'>

                            {isNonWhitespaceString(title) && (
                                <h2 className='h2'>
                                    {title}
                                </h2>
                            )}

                            {isNonWhitespaceString(text) && (
                                <RichText
                                    content={text}
                                    className='text-big'
                                />
                            )}

                        </div>

                        <div className='col-md-6 right'>
                            <RichText content={content} />
                        </div>

                    </div>
                </div>
            </div>
        </Section>
    )
}