// libraries
import React, { useState } from 'react'

// components
import Section from '/react4xp/components/Section'
import Button from '/react4xp/components/Button'
import Form from '/react4xp/components/Form/Form'
import Checkbox from '/react4xp/components/Form/Checkbox'
import Input from '/react4xp/components/Form/Input'
import TextArea from '/react4xp/components/Form/TextArea'
import Select from '/react4xp/components/Form/Select'
import Upload from '/react4xp/components/Form/Upload'

// prop types
interface ContactFormProps {
    strings: {
        heading: string //Send melding

        popupSuccessClose: string //Lukk
        popupSuccessTitle: string //Suksess
        popupSuccessContent: string //Profilen din ble oppdatert.

        popupErrorClose: string //Lukk
        popupErrorTitle: string //Det oppsto en feil under forsøk på å oppdatere innstillingene dine.
        popupErrorContent: string //Vennligst prøv igjen om noen minutter. Hvis problemet vedvarer, vennligst kontakt oss.

        inputNameLabel: string //Navn (obligatorisk)
        inputEmailLabel: string //E-postadresse (obligatorisk)
        inputPhoneLabel: string //Telefon (obligatorisk)

        inputInquiryLabel: string //Hva gjelder henvendelsen
        inputInquiryDefaultLabel: string //Velg
        inputInquiryComplaintLabel: string //Reklamasjon
        inputInquiryOtherLabel: string //Annet

        inputProductLabel: string //Gjelder det et produkt
        inputProductDefaultLabel: string //Velg produkt
        inputProductNumberLabel: string //Datostempel og nummer på produkt

        inputTextLabel: string //Skriv din melding
        inputTextIntro: string //Ikke skriv personsensitiv informasjon som fødselsnummer og annen personlig informasjon i skjemaet.
        inputTextMaxLimit: string //tegn
        inputUploadLabel: string//Last opp bilde
        inputCheckboxLabelBefore: string //Jeg godkjenner
        inputCheckboxLabelAfter: string //personvernvilkår

        validationRequired: string //Dette feltet er obligatorisk
        validationMaxLength: string //Maksimalt antall tegn overskredet
        validationMinLength: string //Meldingen er for kort
        validationMaxUpload: string //Filstørrelsen overskrider maksimalgrensen på

        submitButton: string //Send inn
    }
    sendEmailServiceUrl: string
    termsAndConditionsUrl: string
    products?: {
        label: string
        value: string
    }[]
    user?: {
        firstName?: string
        lastName?: string
        phone?: string
        email?: string
    }
}

export default function ContactForm({
    strings,
    sendEmailServiceUrl,
    termsAndConditionsUrl,
    products,
    user,
}: ContactFormProps) {

    // make the 2 additional fields below the inquiry hidden by default
    const [showHiddenFields, setShowHiddenFields] = useState(false)

    // if the user selects 'complaint' option, show the 2 additional fields
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = e.target.options[e.target.selectedIndex]
        const shouldShowFields = selectedOption.id === 'show-additional-fields'
        setShowHiddenFields(shouldShowFields)
    }

    // create an empty state for the selected product
    const [selectedProduct, setSelectedProduct] = useState('')

    // change the above state when the user selects a product
    // we will use this to make the date-stamp and upload fields required / mandatory or not
    const handleProductSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(e.target.value)
    }

    const inquiries = [
        { name: strings.inputInquiryComplaintLabel, value: 'complaint', makeOtherFieldsRequired: true },
        { name: strings.inputInquiryOtherLabel, value: 'other', makeOtherFieldsRequired: false }
    ]

    return (
        <Section
            className='page-contact__form'
            bgColor='yellow-light'
        >
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4'>

                        <h2 className='h2'>
                            {strings.heading}
                        </h2>

                        <Form
                            isFormData
                            endpoint={sendEmailServiceUrl}
                            className='form'
                            popupSuccessContent={{
                                hasCloseButton: true,
                                closeButtonText: strings.popupSuccessClose,
                                title: strings.popupSuccessTitle,
                                content: strings.popupSuccessContent,
                                actionButtons: {
                                    primary: {
                                        text: strings.popupSuccessClose,
                                        closeOnClick: true
                                    }
                                },
                                onClose: () => {
                                    window.location.reload()
                                }
                            }}
                            popupErrorContent={{
                                hasCloseButton: true,
                                closeButtonText: strings.popupErrorClose,
                                title: strings.popupErrorTitle,
                                content: strings.popupErrorContent,
                                actionButtons: {
                                    primary: {
                                        text: strings.popupErrorClose,
                                        closeOnClick: true
                                    }
                                }
                            }}
                        >
                            <Input
                                name='name'
                                id='contact-name'
                                label={strings.inputNameLabel}
                                type='text'
                                required
                                value={`${user?.firstName || ''} ${user?.lastName || ''}`.trim()}
                                errorMessages={{
                                    required: strings.validationRequired
                                }}
                                size='full-width'
                            />

                            <Input
                                name='email'
                                id='contact-email'
                                label={strings.inputEmailLabel}
                                type='email'
                                required
                                value={user?.email || ''}
                                errorMessages={{
                                    required: strings.validationRequired
                                }}
                                size='full-width'
                            />

                            <Input
                                name='phone'
                                id='contact-phone'
                                label={strings.inputPhoneLabel}
                                type='tel'
                                size='medium'
                                required
                                value={user?.phone || ''}
                                errorMessages={{
                                    required: strings.validationRequired
                                }}
                            />

                            <Select
                                name='inquiry'
                                id='contact-inquiry'
                                label={strings.inputInquiryLabel}
                                size='large'
                                required
                                defaultValue=''
                                onChange={handleSelectChange}
                                errorMessages={{
                                    required: strings.validationRequired
                                }}
                            >
                                
                                <option value='' disabled>
                                    {strings.inputInquiryDefaultLabel}
                                </option>

                                {inquiries.map((inquiry, index) => (
                                    <option
                                        key={index}
                                        value={inquiry.value}
                                        id={inquiry.makeOtherFieldsRequired ? 'show-additional-fields' : null}
                                    >
                                        {inquiry.name}
                                    </option>
                                ))}

                            </Select>

                            {showHiddenFields && (
                                <>

                                    <Select
                                        name='product'
                                        id='contact-inquiry-product'
                                        label={strings.inputProductLabel}
                                        size='full-width'
                                        defaultValue=''
                                        onChange={handleProductSelectChange}
                                    >
                                        <option value=''>
                                            {strings.inputProductDefaultLabel}
                                        </option>
                                        {
                                            products && products.map((product, index) => 
                                                <option
                                                    key={index}
                                                    value={product.value}
                                                >
                                                    {product.label}
                                                </option>
                                            )
                                        }

                                    </Select>

                                    <Input
                                        name='product_number'
                                        id='contact-date-stamp'
                                        label={strings.inputProductNumberLabel}
                                        type='text'
                                        errorMessages={{
                                            required: strings.validationRequired
                                        }}
                                        size='full-width'
                                    />

                                </>
                            )}

                            <div className='textarea'>

                                <p className='text-small'>
                                    {strings.inputTextLabel}
                                </p>

                                <p className='text-smallest'>
                                    {strings.inputTextIntro}
                                </p>

                                <TextArea
                                    name='text'
                                    id='contact-message'
                                    minLength={5}
                                    maxLength={{
                                        limit: 2000,
                                        text: strings.inputTextMaxLimit
                                    }}
                                    required
                                    errorMessages={{
                                        required: strings.validationRequired,
                                        maxLength: strings.validationMaxLength,
                                        minLength: strings.validationMinLength
                                    }}
                                />

                            </div>

                            <Upload
                                id='contact-upload'
                                label={strings.inputUploadLabel}
                                name='upload-file'
                                className='upload'
                                accept='image/*, application/pdf, application/zip'
                                multiple={true}
                                maxFileSize={25} // this will always be mb
                                errorMessages={{
                                    required: strings.validationRequired,
                                    maxFileSize: strings.validationMaxUpload // this message will only appear in an alert if the use uploads a file larger than 25mb
                                }}
                            />

                            <Checkbox
                                id='contact-newsletter'
                                name='terms'
                                required
                                errorMessages={{
                                    required: strings.validationRequired
                                }}
                            >
                                {strings.inputCheckboxLabelBefore} <a href={termsAndConditionsUrl}>{strings.inputCheckboxLabelAfter}</a>
                            </Checkbox>

                            <Button
                                color='black'
                                hover='red'
                                type='submit'
                                noUnderline
                            >
                                {strings.submitButton}
                            </Button>

                        </Form>

                    </div>
                </div>
            </div>
        </Section>
    )
}
