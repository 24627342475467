//libraries
import React from 'react'

// components
import Section from '/react4xp/components/Section'
import Video from '/react4xp/components/Video'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
export interface ListVideosProps {
    section?: {
        bgColor: 'white' | 'red-light' | 'yellow-light'
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
    }
    title?: string
    videos: Array<{
        videoType: 'youtube' | 'vimeo' | 'default'
        playText: string
        poster?: string
        posterMedium?: string
        url: string
    }>
}

export default function ListVideos({
    section,
    title,
    videos
}: ListVideosProps) {
    return (
        <Section
            bgColor={section?.bgColor}
            paddingTop={section?.paddingTop}
            paddingBottom={section?.paddingBottom}
            className='list-videos'
        >
            <div className='container'>
                <div className='row'>
                    <div className='col-xl-10 offset-xl-1'>

                        {isNonWhitespaceString(title) && (
                            <h2 className='title-top h2'>
                                {title}
                            </h2>
                        )}

                        {videos && (
                            <div className='list-videos__videos'>
                                {videos.map((video, index) => (
                                    <Video
                                        key={index}
                                        video={{
                                            videoType: video.videoType,
                                            playText: video.playText,
                                            playButtonColor: 'red',
                                            playTextColor: 'white',
                                            poster: video.poster, // 500x300
                                            posterMedium: video.poster, // 500x300
                                            url: video.url
                                        }}
                                    />
                                ))}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </Section>
    )
}