// libraries
import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'

// components
import Image from '/react4xp/components/Image'

// prop types
interface VideoProps {
    className?: string
    video: {
        url: string
        playButtonColor: 'white' | 'blue' | 'red' | string
        playText: string
        playTextColor: 'white' | 'black'
        videoType: 'youtube' | 'vimeo' | 'default'
        poster?: string
        posterMedium?: string
    }
}

export default function Video({ video, className }: VideoProps) {

    const [isPlaying, setIsPlaying] = useState(false)
    const [currentPoster, setCurrentPoster] = useState(video.poster)
    const [showPoster, setShowPoster] = useState(true)
    const videoRef = useRef<HTMLVideoElement>(null)
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const [mute, setMute] = useState(0)

    const handleVideoClick = () => {

        if (video.videoType === 'default') {

            setIsPlaying(!isPlaying)
            
            if (isPlaying) {
                videoRef.current?.pause()
            } else {
                videoRef.current?.play()
            }

        } else if (video.videoType === 'youtube') {
            iframeRef.current?.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
            setIsPlaying(true)
        } else if (video.videoType === 'vimeo') {
            iframeRef.current?.contentWindow?.postMessage('{"method":"play"}', '*')
            setIsPlaying(true)
        }

        setShowPoster(false)
    }

    useEffect(() => {
        const handlePlayPause = () => {
            setIsPlaying(!videoRef.current?.paused)
        }

        const videoElement = videoRef.current
        videoElement?.addEventListener('play', handlePlayPause)
        videoElement?.addEventListener('pause', handlePlayPause)

        return () => {
            videoElement?.removeEventListener('play', handlePlayPause)
            videoElement?.removeEventListener('pause', handlePlayPause)
        }
    }, [])

    // change the poster based on the screen size
    useEffect(() => {
        const updatePoster = () => {
            if (window.innerWidth < 767 && video.posterMedium) {
                setCurrentPoster(video.posterMedium)
            } else {
                setCurrentPoster(video.poster)
            }
        }

        updatePoster()

        window.addEventListener('resize', updatePoster)
        return () => window.removeEventListener('resize', updatePoster)
    }, [video.poster, video.posterMedium])

    // set mute based on the screen size
    const setMuteValue = () => {
        setMute(window.innerWidth > 1023 ? 0 : 1)
    }

    useEffect(() => {
        setMuteValue()
        window.addEventListener('resize', setMuteValue)
        return () => {
            window.removeEventListener('resize', setMuteValue)
        }
    }, [])

    // custom bg color
    const customBgColor = video.playButtonColor && !['white', 'blue', 'red'].includes(video.playButtonColor) ? { backgroundColor: video.playButtonColor } : {}

    return (
        <div
            className={clsx('video-wrapper', className)}
            onClick={handleVideoClick}
        >

            <button
                onClick={handleVideoClick}
                className='video-wrapper__button'
            ></button>

            {!isPlaying && (
                <div
                    className={clsx(
                        'video-wrapper__play-pause',
                        video.playTextColor === 'black' && 'black',
                        video.playTextColor === 'white' && 'white',

                        // bg colors
                        video.playButtonColor === 'white' && 'bg-white',
                        video.playButtonColor === 'blue' && 'bg-blue',
                        video.playButtonColor === 'red' && 'bg-red'
                    )}
                    style={customBgColor}
                >
                    {video.playText}
                </div>
            )}

            {video.videoType === 'default' && (
                <>

                    {!isPlaying && showPoster && currentPoster && (
                        <Image
                            alt='Video'
                            src={currentPoster}
                            className='cover thumb'
                        />
                    )}

                    <video
                        ref={videoRef}
                        className='cover'
                        loop
                        poster={currentPoster}
                        controls={isPlaying}
                        //preload='none'
                    >
                        <source src={video.url + '#t=0.001'} />
                    </video>

                </>
            )}

            {video.videoType === 'youtube' && (
                <>

                    {!isPlaying && showPoster && currentPoster && (
                        <Image
                            alt='Video'
                            src={currentPoster}
                            className='cover thumb'
                        />
                    )}

                    <iframe
                        ref={iframeRef}
                        className='cover'
                        src={`https://www.youtube.com/embed/${video.url}?autoplay=0&playsinline=1&mute=${mute}&loop=0&modestbranding=1&rel=0&iv_load_policy=3&title=0&controls=1&portrait=0&enablejsapi=1`}
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        tabIndex={isPlaying ? 0 : -1}
                    ></iframe>
                    
                </>
            )}

            {video.videoType === 'vimeo' && (
                <>

                    {!isPlaying && currentPoster && (
                        <Image
                            alt='Video'
                            src={currentPoster}
                            className='cover thumb'
                        />
                    )}

                    <iframe
                        ref={iframeRef}
                        className='cover'
                        src={`https://player.vimeo.com/video/${video.url.split('/').pop()}?title=0&byline=0&portrait=0&controls=1`}
                        allow='autoplay; fullscreen; picture-in-picture'
                        allowFullScreen
                        tabIndex={isPlaying ? 0 : -1}
                    ></iframe>

                </>
            )}

        </div>
    )
}