//libraries
import React from 'react'

// prop types
export interface CardProps {
    title?: string
    number?: number
    percentage?: number
    smallText?: string
}

export default function Card({
    title,
    number,
    percentage,
    smallText
}: CardProps) {
    return (
        <div className='dash-card'>

            {title && (
                <p className='dash-bigger dash-card__title'>
                    {title}
                </p>
            )}

            {(number >= 0) && (
                <div className='dash-card__number'>

                    <p className='dash-h4 number'>
                        {number}
                    </p>

                    {percentage && (
                        <div className='dash-card__percentage'>

                            {percentage !== undefined && percentage >= 0 ? (
                                <svg width='14' height='18' viewBox='0 0 14 18' xmlns='http://www.w3.org/2000/svg'><path d='M13.4401 7.42493L7.00014 0.727051L0.560136 7.42493C0.484154 7.48542 0.42149 7.56227 0.376388 7.65028C0.331285 7.73829 0.304797 7.83541 0.298716 7.93505C0.292635 8.0347 0.307103 8.13454 0.341141 8.22783C0.375179 8.32112 0.427992 8.40567 0.496005 8.47577C0.564017 8.54587 0.645642 8.59987 0.735352 8.63411C0.825063 8.66836 0.920764 8.68206 1.01598 8.67428C1.11119 8.6665 1.2037 8.63742 1.28723 8.58902C1.37076 8.54061 1.44337 8.47401 1.50014 8.39372L6.33347 3.38251V16.7155C6.33347 16.9004 6.40371 17.0777 6.52873 17.2084C6.65376 17.3391 6.82333 17.4125 7.00014 17.4125C7.17695 17.4125 7.34652 17.3391 7.47154 17.2084C7.59657 17.0777 7.6668 16.9004 7.6668 16.7155V3.38251L12.5001 8.39372C12.6257 8.52404 12.7956 8.59688 12.9725 8.59623C13.1494 8.59557 13.3188 8.52148 13.4435 8.39023C13.5681 8.25899 13.6378 8.08136 13.6372 7.89641C13.6366 7.71146 13.5657 7.53434 13.4401 7.40402V7.42493Z'/></svg>
                            ) : (
                                <svg width='14' height='18' viewBox='0 0 14 18' xmlns='http://www.w3.org/2000/svg'><path d='M0.494471 10.7147L6.93443 17.4126L13.3744 10.7147C13.4504 10.6542 13.5131 10.5774 13.5582 10.4894C13.6033 10.4014 13.6298 10.3042 13.6359 10.2046C13.6419 10.1049 13.6275 10.0051 13.5934 9.91182C13.5594 9.81853 13.5066 9.73398 13.4386 9.66388C13.3706 9.59378 13.2889 9.53978 13.1992 9.50554C13.1095 9.47129 13.0138 9.45759 12.9186 9.46537C12.8234 9.47315 12.7309 9.50223 12.6473 9.55063C12.5638 9.59904 12.4912 9.66564 12.4344 9.74593L7.6011 14.7571L7.6011 1.42415C7.6011 1.23925 7.53086 1.06195 7.40584 0.931247C7.28081 0.800547 7.11124 0.727147 6.93443 0.727147C6.75762 0.727147 6.58805 0.800547 6.46303 0.931247C6.338 1.06195 6.26777 1.23925 6.26777 1.42415L6.26777 14.7571L1.43447 9.74593C1.30887 9.61561 1.13897 9.54277 0.962071 9.54342C0.785171 9.54408 0.615771 9.61817 0.491071 9.74942C0.366471 9.88066 0.296771 10.0583 0.297371 10.2432C0.297971 10.4282 0.368871 10.6053 0.494471 10.7356L0.494471 10.7147Z'/></svg>
                            )}

                            <p className='dash-smaller'>
                                {percentage}%
                            </p>

                        </div>
                    )}

                </div>
            )}

            {smallText && percentage && (
                <p className='dash-card__small-text dash-smaller'>
                    {smallText}
                </p>
            )}

        </div>
    )
}