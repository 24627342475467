// libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import Image from '/react4xp/components/Image'
import RichText from '/react4xp/components/RichText'

import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// svg
import { UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'
import { Nyhet, OneHundredAr, Fint, Goot, Mykt, Nam, BliMed, Goy, Heart, Lett, Protein } from '/react4xp/components/Svg/Others/NewProductsIcon'

// prop types
interface ProductBlockHorizontalProps {

    // section props
    section: {
        bgColor: 'white' | 'white-dark' | 'yellow-light' | 'red-light'
        paddingTop?: 'small' | 'medium' | 'big' | 'none'
        paddingBottom?: 'small' | 'medium' | 'big' | 'none'
    }

    // product card props
    productPosition: 'left' | 'right'
    tilt?: 'left' | 'right'
    icon?: {
        src: 'nyhet' | '100ar' | 'fint' | 'goot' | 'mykt' | 'nam' | 'bli med' | 'goy' | 'heart' | 'lett' | 'protein' | string
        position: 'top-left' | 'top-right' | 'center-left' | 'center-right' | 'bottom-left' | 'bottom-right'
    }
    src: string
    alt?: string
    title: string
    desc: string
    href: string
    external?: boolean
    className?: string
}

export default function ProductBlockHorizontal({
    section,
    productPosition,
    tilt,
    icon,
    src,
    alt,
    title,
    desc,
    href,
    external,
    className
}: ProductBlockHorizontalProps) {

    const Element = href ? 'a' : 'div'

    const elementProps = {
        href: href || null,
        target: external ? '_blank' : undefined,
    }
    
    return (
        <Section
            bgColor={section.bgColor}
            paddingTop={section.paddingTop}
            paddingBottom={section.paddingBottom}
            className='product-block-horizontal'
        >
            <div className='container'>
                <div className='row'>
                    <div className='offset-lg-1 col-lg-10'>
                        <div
                            className={clsx(
                                'product-block-horizontal__link',

                                // product position
                                productPosition === 'left' && 'product-block-horizontal__link--left',
                                productPosition === 'right' && 'product-block-horizontal__link--right',

                                // tilt (rotate the image 20deg left or right)
                                tilt === 'left' && 'product-block-horizontal__link--tilt-left',
                                tilt === 'right' && 'product-block-horizontal__link--tilt-right',

                                className
                            )}
                        >
                            
                            <Element {...elementProps} className='product-block-horizontal__image'>
                                <>

                                    {icon.src && (
                                        <div className={clsx(
                                            'icon',
                                            icon.position === 'top-left' && 'top-left',
                                            icon.position === 'top-right' && 'top-right',
                                            icon.position === 'center-left' && 'center-left',
                                            icon.position === 'center-right' && 'center-right',
                                            icon.position === 'bottom-left' && 'bottom-left',
                                            icon.position === 'bottom-right' && 'bottom-right'
                                        )}>
                                            {icon.src === 'nyhet' && <Nyhet />}
                                            {icon.src === '100ar' && <OneHundredAr />}
                                            {icon.src === 'fint' && <Fint />}
                                            {icon.src === 'goot' && <Goot />}
                                            {icon.src === 'mykt' && <Mykt />}
                                            {icon.src === 'nam' && <Nam />}
                                            {icon.src === 'bli med' && <BliMed />}
                                            {icon.src === 'goy' && <Goy />}
                                            {icon.src === 'heart' && <Heart />}
                                            {icon.src === 'lett' && <Lett />}
                                            {icon.src === 'protein' && <Protein />}

                                            {!['nyhet', '100ar', 'fint', 'goot', 'mykt', 'nam', 'bli med', 'goy', 'heart', 'lett', 'protein'].includes(icon.src) && (
                                                <Image
                                                    src={icon.src}
                                                    alt={clsx(
                                                        icon.src === 'nyhet' && 'Nyhet',
                                                        icon.src === '100ar' && '100Ar',
                                                        icon.src === 'fint' && 'Fint',
                                                        icon.src === 'goot' && 'Goot',
                                                        icon.src === 'mykt' && 'Mykt',
                                                        icon.src === 'nam' && 'Nam',
                                                        icon.src === 'bli med' && 'BliMed',
                                                        icon.src === 'goy' && 'Goy',
                                                        icon.src === 'heart' && 'Heart',
                                                        icon.src === 'lett' && 'Lett',
                                                        icon.src === 'protein' && 'Protein'
                                                    )}
                                                />
                                            )}

                                        </div>
                                    )}

                                    {src && (
                                        <Image
                                            src={src}
                                            alt={alt}
                                            width={500}
                                            className='product'
                                        />
                                    )}

                                </>
                            </Element>

                            <div className='product-block-horizontal__box bg-white'>

                                {isNonWhitespaceString(title) && (
                                    <Element {...elementProps}>
                                        <h3 className='h2'>
                                            {title}
                                        </h3>
                                    </Element>
                                )}

                                {isNonWhitespaceString(desc) && (
                                    <RichText
                                        content={desc}
                                        className='product-block-horizontal__box__desc'
                                    />
                                )}

                                <Element {...elementProps} className='product-block-horizontal__box__arrow'>
                                    <UxArrowRight />
                                </Element>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}