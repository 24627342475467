// libraries
import React from 'react'
import clsx from 'clsx'

// utils
import { isNonWhitespaceString } from '/lib/reactutils/utils.js'

// prop types
interface MacroInfoBoxProps {
    title?: string
    content?: string
    className?: string
}

export default function MacroInfoBox({
    title,
    content,
    className
}: MacroInfoBoxProps) {
    return (
        <div className={clsx('macros--info-box', className)}>

            {isNonWhitespaceString(title) && (
                <h3 className='h3'>
                    {title}
                </h3>
            )}

            {isNonWhitespaceString(content) && (
                <div
                    className='macros--info-box__content'
                    dangerouslySetInnerHTML={{ __html: content }}
                    suppressHydrationWarning
                />
            )}

        </div>
    )
}